import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import alliances from '../../images/alliances.svg';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';

const Alliances = () => {
  const classes = Structure();

  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const styleMobile = {
    title: {
      color: '#3155A4',
      marginLeft: '15vw',
    },
    social: {
      marginTop: '40px',
      marginLeft: '30vw',
    },
  };
  const style = {
    title: {
      color: '#3155A4',
    },
    social: {
      marginTop: '40px',
      float: 'right',
    },
  };

  return (
    <Grid container spacing={1} style={{ backgroundColor: '#CBD5E0' }}>
      <Grid item xs={12} md={6}>
        <div style={{ height: '107px' }}>
          <Box display="flex" justifyContent="center" height="100%">
            <Box>
              <Typography
                variant="h6"
                style={isMobile ? styleMobile.title : style.title}
              >
                NUESTRAS ALIANZAS
              </Typography>
              <img src={alliances} alt="Alianzas" height="100px" />
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ height: '107px' }}>
          <Box display="flex" justifyContent="center" height="100%">
            <Box>
              <Typography variant="h6" style={{ color: '#3155A4' }}>
                Comenta y participa utilizando{' '}
                <label style={{ fontWeight: 'bolder' }}>#seniortalent</label>
              </Typography>
              <div style={isMobile ? styleMobile.social : style.social}>
                <FacebookIcon fontSize="large" />
                <TwitterIcon fontSize="large" />
                <LinkedInIcon fontSize="large" />
                <InstagramIcon fontSize="large" />
                <RssFeedIcon fontSize="large" />
              </div>
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Alliances;
