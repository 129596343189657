import React, { useState } from 'react'
import {
  TextField,
  Box,
  Typography,
  Grid,

  InputAdornment,
  Button
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import BasicTable from '../table'
import { v4 as uuidv4 } from 'uuid'
import { checkEmail, checkFullName, checkPhone } from '../../helpers/validate'
import { FONT_FAMILY } from '../../helpers/constants';

const defaultBoxProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
  style: { width: 'auto', height: 'auto' }
}

const tableHeadData = [
  { key: 'name', value: 'Nombre' },
  { key: 'position', value: 'Cargo' },
  { key: 'company', value: 'Empresa' },
  { key: 'phone', value: 'Teléfono' },
  { key: 'email', value: 'Correo' }
]

const initialCurrentReference = {
  name: '',
  position: '',
  company: '',
  phone: '',
  email: ''
}

const schemaValidation = {
  name: checkFullName,
  position: checkFullName,
  company: (n) => n.trim().length > 0,
  phone: checkPhone,
  email: checkEmail
}

const initialErrorValues = {
  name: false,
  position: false,
  company: false,
  phone: false,
  email: false
}

const style = {
  labelForm: {
    fontFamily: FONT_FAMILY,
    color: '#1A202C',
    fontSize: '18px',
    fontWeight:'bold'
  },
  subLabelForm:{
    fontFamily: FONT_FAMILY,
    color: '#000000',
    fontSize: '12px'
  },
  inputForm: {
    fontFamily: FONT_FAMILY,
    color: '#CBD5E0'
  },
  buttonSubmit: {
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  }
}

const References = (props) => {
  const { value, setValues, setError } = props

  const [reference, setReference] = useState(initialCurrentReference)
  const [errors, setErrors] = useState(initialErrorValues)
  // const [newError, setNewError] = useState(false);

  const updateReferenceValues = () => {
    setValues(new Map(value))
    setError(new Map(value))
  }

  //    useEffect(() => {
  //        setNewError(false);
  //    }, [reference]);

  /**
   *
   * @returns
   */
  const validateReference = () => {
    let result = true
    let newErrorState = {}

    ;['name', 'position', 'company', 'phone', 'email'].forEach((field) => {
      let checkField = !schemaValidation[field](reference[field])
      newErrorState[field] = checkField

      if (checkField) {
        result = !checkField
      }
    })

    setErrors(newErrorState)

    return result
  }

  /**
   * Handle add new reference action
   */
  const handleAddReferenceClicked = () => {
    if (!validateReference()) {
      alert(
        'La referencia tiene errores, por favor revise los valores ingresados.'
      )
      return false
    }

    // Update state with reference
    setValues(new Map(value.set(uuidv4(), reference)))

    updateReferenceValues()

    // Clean add new reference form
    setReference({
      name: '',
      position: '',
      company: '',
      phone: '',
      email: ''
    })
  }

  /**
   * Handler of internal reference state
   * @param {*} e
   */
  const handleReferenceInputChange = (e) => {
    const { name, value } = e.target

    setReference({
      ...reference,
      [name]: value
    })

    setErrors({
      ...errors,
      [name]: !schemaValidation[name](value)
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Nombre
              </Typography>
              <Typography style={style.subLabelForm} variant="subtitle1">Última jefatura directa relevante</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='name'
                variant='outlined'
                label=''
                placeholder='Ricardo Arriagada'
                value={reference ? reference.name : ''}
                onChange={handleReferenceInputChange}
                size='small'
                error={errors.name}
                helperText={
                  errors.name ? 'Por favor ingrese su nombre completo.' : ''
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Cargo
              </Typography>
              <Typography style={style.subLabelForm} variant="subtitle1"></Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='position'
                variant='outlined'
                label=''
                placeholder=''
                value={reference ? reference.position : ''}
                onChange={handleReferenceInputChange}
                size='small'
                error={errors.position}
                helperText={
                  errors.position ? 'Por favor ingrese cargo.' : ''
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Empresa
              </Typography>
              <Typography style={style.subLabelForm} variant="subtitle1">Última empresa en la que estuviste relevante</Typography>
           </Grid>
            <Grid item xs={8}>
              <TextField
                name='company'
                variant='outlined'
                label=''
                placeholder='Los tres Cabritos'
                value={reference.company}
                onChange={handleReferenceInputChange}
                size='small'
                error={errors.company}
                helperText={
                  errors.company
                    ? 'Por favor ingrese nombre de la empresa.'
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Teléfono
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='phone'
                variant='outlined'
                label=''
                placeholder='xxxxxx'
                value={reference.phone}
                onChange={handleReferenceInputChange}
                size='small'
                error={errors.phone}
                helperText={
                  errors.phone
                    ? 'Por favor ingrese un teléfono de contacto. Ej.: +56 9 55554444'
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>+56 9</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Correo Electrónico
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='email'
                variant='outlined'
                label=''
                placeholder='Rarriagada@xxxx.cl'
                value={reference.email}
                onChange={handleReferenceInputChange}
                size='small'
                error={errors.email}
                helperText={
                  errors.email
                    ? 'Por favor ingrese un correo válido. Ej.: empresa@seniortalent.cl'
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

        <Grid container justify='flex-end'>
        <Box m={3}>
          <Button
            style={style.buttonSubmit}
            aria-label='add reference'
            variant='contained'
            onClick={handleAddReferenceClicked}
          >
            Agregar <Add></Add>
          </Button>
          </Box>
        </Grid>


      <Grid item xs={12}>
      <Box m={3}>
        <BasicTable
          headData={tableHeadData}
          tableData={value}
          tableCellProcess={(a, s, l) => l[a]}
          setTableData={setValues}
        />
         </Box>
      </Grid>

    </Grid>
  )
}

export default References
