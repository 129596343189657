import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  InputLabel,
  Link,
} from '@material-ui/core';
import { FONT_FAMILY } from '../../helpers/constants';
import { Privacity } from '../privacity';
import { Terms } from '../terms';

const Footer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setOpenDialog(dialogType.length > 0);
  }, [dialogType]);

  const handleShowDialog = (e) => {
    const { currentTarget } = e;
    const dialogType = currentTarget.getAttribute('data');

    setDialogType(dialogType);

    // console.log(`Abrir form of type ${formType} `);
  };

  const classes = Structure();

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const styleMobile = {
    footer: {
      backgroundColor: '#0A1746',
      color: 'white',
      width: '130vw',
    },
  };
  const style = {
    footer: {
      height: '100px',
      backgroundColor: '#0A1746',
      color: 'white',
    },
  };

  return (
    <Grid
      container
      style={isMobile ? styleMobile.footer : style.footer}
      spacing={1}
    >
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setDialogType('')}
        aria-labelledby="form-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ textAlign: 'center' }}>
            <InputLabel
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: '34px',
                color: '#3155A4',
              }}
            >
              {dialogType == 'terminos'
                ? 'TÉRMINOS Y CONDICIONES DE USO'
                : 'POLÍTICAS DE PRIVACIDAD'}
            </InputLabel>
          </div>
        </DialogTitle>
        {dialogType == 'terminos' ? (
         <Terms></Terms>
        ) : (
         <Privacity></Privacity>
        )}
      </Dialog>

      <Grid item xs={4}>
        <Box
          data="terminos"
          style={{ cursor: 'pointer' }}
          onClick={handleShowDialog}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="subtitle1" style={{ marginTop: '30px' }}>
            Términos y Condiciones
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          data="politicas"
          style={{ cursor: 'pointer' }}
          onClick={handleShowDialog}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="subtitle1" style={{ marginTop: '30px' }}>
            Políticas de Privacidad
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="subtitle1" style={{ marginTop: '30px' }}>
            Copyright 2020 (c) SeniorTalent Chile
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
