import React, { useState } from 'react';
import interestValues from '../../datasource/interets.json';
import { FormHelperText, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, FormControl, Typography, Grid, Box } from '@material-ui/core';
import { FONT_FAMILY } from '../../helpers/constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  labelForm: {
    fontFamily: FONT_FAMILY,
    color: '#1A202C',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  subLabelForm:{
    fontFamily: FONT_FAMILY,
    color: '#000000',
    fontSize: '12px'
  },
  inputForm: {
    fontFamily: FONT_FAMILY,
    color: '#CBD5E0'
  },
  buttonSubmit: {
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  }
}

const Interests = (props) => {
    const {
        name,
        value,
        error,
        helperText,
        handleInputChange
    } = props;

    return (
      <Box m={2}>
      <Grid
        m={4}
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={4}>
          <Typography style={style.labelForm} component='h5'>
            Área de Interés
          </Typography>
          <Typography style={style.subLabelForm} variant="subtitle1">En qué area te gustaría trabajar de acuerdo a tu experiencia</Typography>

        </Grid>
        <Grid item xs={8}>
        <FormControl variant="outlined" error={error}>
                <Select
                    labelId="mutiple-checkbox-label"
                    name={name}
                    multiple
                    value={value}
                    onChange={handleInputChange}
                    input={<OutlinedInput />}
                    renderValue={(value) => value.join(', ')}
                    MenuProps={MenuProps}
                >
                    {interestValues.map((interest) => (
                        <MenuItem key={interestValues.indexOf(interest)} value={interest}>
                            <Checkbox checked={value.indexOf(interest) > -1} />
                            <ListItemText primary={interest} />
                        </MenuItem>
                    ))}
                </Select>
                {error ?
                    <FormHelperText>{helperText}</FormHelperText>
                : ""}
            </FormControl>
        </Grid>
      </Grid>
    </Box>
    )
}

export default Interests;
