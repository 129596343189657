import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

export const Privacity = () => {
  return (
    <Box m={6} flex justifyContent="space-between" align="justify">
            <Typography variant="body1" paragraph="true">
              Senior Talent SpA y sus empresas afiliadas, subsidiarias o
              controladoras (“
              <Box component="span" fontWeight="fontWeightBold">
                Senior Talent
              </Box>
              ”), respetan la privacidad de toda persona que visite el sitio web{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.seniortalent.cl"
              >
                www.seniortalent.cl
              </Link>
              .
            </Typography>
            <Typography variant="body1" paragraph="true">
              Estas políticas de privacidad (“
              <Box component="span" fontWeight="fontWeightBold">
                Políticas de Privacidad
              </Box>
              ”) regulan la forma en que Senior Talent tratará la información y
              datos personales de los usuarios que visitan los sitios web
              controlados por Senior Talent en donde se publiquen estas
              Políticas de Privacidad (colectivamente los “
              <Box component="span" fontWeight="fontWeightBold">
                Sitios
              </Box>
              ”) o a otras funciones interactivas o descargas que sean de
              propiedad o controladas por Senior Talent y que estén disponibles
              a través de los Sitios, o que interactúen con los Sitios.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Por favor revise cuidadosamente estas Políticas de Privacidad. Si
              tiene preguntas o inquietudes con respecto a estas Políticas de
              Privacidad, comuníquese al siguiente correo electrónico
              contacto@seniortalent.cl
            </Typography>
            <Typography variant="body1" paragraph="true">
              Al hacer clic en el cuadro correspondiente en la página de
              registro de esta Política de privacidad, acepta estos términos y
              acepta estar sujeto a este instrumento.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                1. DEFINICIONES.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Información Personal"
              </Box>
              : cualquier información relacionada con una persona o entidad
              identificada o identificable (dependiendo de la jurisdicción desde
              la cual usted acceda a los Sitios), como nombre personal,
              dirección postal, dirección de correo electrónico y número de
              teléfono; fecha de nacimiento; género; número de identidad; nombre
              de la empresa, dirección postal, dirección de correo electrónico,
              número de teléfono; y, antecedentes académicos y laborales, entre
              otros.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Base de Datos"
              </Box>
              : cualquier conjunto organizado de Información Personal que esté
              sujeto al Procesamiento de Información (como se define a
              continuación), por medios electrónicos o de cualquier otra forma,
              cualquiera que sea el modo de recolección, almacenamiento,
              organización o acceso.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Procesamiento de la Información"
              </Box>
              : cualquier operación y proceso sistemático, electrónico u otro,
              que permita la recopilación, registro, organización,
              almacenamiento, alteración, vinculación, evaluación, bloqueo,
              borrado, disociación y en general el Procesamiento de Información
              Personal; mediante transmisión búsquedas, interconexiones o
              transferencias.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Controlador de Datos" o "Controlador de un archivo, registro,
                base de datos o banco de datos"
              </Box>
              : la persona física o jurídica, pública o privada, que sea titular
              de un archivo, registro, Base de Datos o banco de datos o que
              tenga la autoridad para decidir cómo y por qué la Información
              Personal será procesada.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Disociación de la Información"
              </Box>
              : cualquier Procesamiento de Información Personal para que la
              información obtenida no pueda ser asociada con ninguna persona
              identificada o identificable.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Parte Relacionada"
              </Box>
              : cualquier afiliado, entidad que controle Senior Talent y/o
              filiales de Senior Talent.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Promoción"
              </Box>
              : significa cualquier sorteo, concursos y otras promociones
              ofrecidas a través de los Sitios que requieran registrarse.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Proveedores de Servicios"
              </Box>
              : cualquier proveedor de servicios de terceros, que no sea una
              Parte Relacionada, que puede realizar ciertos servicios para
              nosotros, tales como alojamiento de sitios web, cumplimiento de
              pedidos, entrega de materiales promocionales y ofertas de
              productos de Senior Talent, compensación y procesamiento de
              crédito y débito, pagos con tarjeta o realizar otros servicios en
              nuestro nombre.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Anunciantes"
              </Box>
              : significa cualquier anunciante de terceros.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                "Sitios Web de Terceros"
              </Box>
              : significa otros sitios web o ubicaciones que son operados y
              controlados por terceros que no sean Senior Talent, sus Partes
              Relacionadas y/o Filiales.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                2. RECOPILACIÓN Y USO DE SU INFORMACIÓN PERSONAL.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Senior Talent no recopilará a través del Sitio ninguna información
              sobre usted que pueda identificarlo personalmente, como, por
              ejemplo, su nombre, dirección, número de teléfono o dirección de
              correo electrónico, a menos que usted nos la brinde en forma
              voluntaria.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Si usted no desea que recopilemos su Información Personal, por
              favor absténgase de proporcionarla. Sin embargo, deberá tener en
              cuenta que en caso de no proporcionarnos su Información Personal,
              no podrá acceder a los servicios e información disponibles a
              través del Sitio.
            </Typography>
            <Typography variant="body1" paragraph="true">
              En caso de que usted nos brinde Información Personal, le
              comunicamos que esa Información Personal será objeto de
              tratamiento automatizado e incorporada a la base de datos de
              Senior Talent.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Uso de Información Personal
              </Box>
              . Senior Talent puede utilizar su Información Personal, ya sea
              recopilada activamente o pasivamente para: (i) proporcionarle
              información o servicios, o procesar transacciones que haya
              solicitado o haya acordado recibir; (ii) procesar su registro con
              los Sitios, incluyendo la verificación de que su dirección de
              correo electrónico está activa y es válida; (iii) mejorar los
              Sitios o nuestros servicios, personalizar su experiencia en los
              Sitios, o con su consentimiento para servirle contenido específico
              que sea relevante para usted; (iv) ponerse en contacto con usted
              con respecto a su uso de los Sitios y, a nuestra discreción, los
              cambios a nuestras políticas; (v) fines comerciales internos; y
              (vi) los propósitos revelados en el momento en que usted
              proporcione su Información Personal.
            </Typography>
            <Typography variant="body1" paragraph="true">
              En todos los casos que usted brinde Información Personal, y de
              acuerdo a la legislación vigente, usted declara que la Información
              Personal brindada es correcta, cierta y actual.
            </Typography>
            <Typography variant="body1" paragraph="true">
              En los casos que nos brinde su Información Personal, usted acepta
              y presta su consentimiento libre, expreso e informado para que
              dicha Información Personal sea utilizada con las finalidades
              arriba mencionadas y autoriza a que la misma sea tratada,
              almacenada, recopilada en las bases de datos de propiedad de
              Senior Talent. En tal sentido, usted autoriza a que su Información
              Personal sea compartida con los clientes de Senior Talent.
              Asimismo, usted acepta y presta su consentimiento libre, expreso e
              informado con los términos de la presente Política de Privacidad.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                3. CORREO ELECTRÓNICO.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Senior Talent podrá enviarle correos electrónicos en relación con
              el contenido del Sitio, los servicios prestados por Senior Talent
              o sobre su cuenta y en respuesta a sus preguntas, pedidos,
              consultas o comentarios. Senior Talent también le podrá enviar
              correos electrónicos con información sobre productos y servicios
              ofrecidos por Senior Talent y/o terceros asociados comercialmente
              que le puedan resultar de interés, a menos que usted indique
              expresamente que no desea recibir dichos correos electrónicos a
              través de los procesos implementados por Senior Talent a tal
              efecto.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Usted podrá solicitar en cualquier momento el retiro o bloqueo de
              su nombre de la base de datos a la que se refiere la presente
              Política de Privacidad siguiendo el procedimiento señalado en esta
              misma política.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Todo correo electrónico que reciba de Senior Talent le informará
              cómo rechazar el envío de futuros correos electrónicos
              promocionales. Asimismo, usted podrá cambiar sus preferencias de
              recepción de correos electrónicos publicitarios a través de la
              configuración de su cuenta en el propio Sitio en cualquier
              momento.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                4. COOKIES.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Cuando usted ingresa en el Sitio podemos almacenar alguna
              información en su computadora bajo la forma de “Cookie” o archivo
              similar que puede sernos útil de varias formas.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Las cookies son archivos de datos que se colocan en una
              computadora cuando se usa para visitar un sitio web. Estas cookies
              pueden utilizarse para muchos propósitos, incluyendo, sin
              limitación, el seguimiento de las preferencias del usuario y las
              páginas web visitadas mientras se utilizan los Sitios y ciertos
              sitios de terceros para fines de marketing y otros. Estos
              proveedores de servicios utilizan cookies o web beacons para
              ayudarnos a ofrecer contenido del sitio, compilar métricas y
              análisis del sitio y ayudarnos a mejorar y personalizar sus
              experiencias en los sitios y en nuestros esfuerzos publicitarios.
              Una lista de estas compañías está disponible a petición. Puede
              desactivar las cookies utilizando las preferencias de su
              navegador. Sin embargo, es posible que algunas funciones de los
              sitios no funcionen correctamente o que funcionen lentamente si
              deshabilita las cookies. Tenga en cuenta también que la
              deshabilitación de cookies eliminará todas las cookies de su
              sistema, incluidas las que considere útiles, como contraseñas y
              nombres de usuario que haya guardado en sitios web visitados con
              frecuencia.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Por favor, consulta las instrucciones de su explorador para
              conocer más sobre estas funciones.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                5. COMPARTIENDO SU INFORMACIÓN PERSONAL.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              La información recopilada pasivamente sobre usted puede combinarse
              o asociarse con su Información Personal. Además, podemos enviarle
              promociones de productos que creemos usted pueda estar interesado
              en recibir, basados en la información recopilada pasivamente. Si
              asociamos cualquier información recopilada pasivamente con
              Información Personal sobre usted, trataremos la información
              combinada como Información Personal.
            </Typography>
            <Typography variant="body1" paragraph="true">
              La Información Personal que usted suministre será reputada y
              tratada como confidencial. Sin perjuicio de lo expuesto, Senior
              Talent podrá compartir total o parcialmente la Información
              Personal suministrada por usted, incluyendo registros de
              comportamiento y actividad en el Sitio, contactos a terceros que
              anuncien u ofrezcan productos o servicios en el Sitio, parámetros
              de interés de búsquedas así como su historial de búsqueda, a los
              anunciantes del Sitio Web, y otros con la finalidad de proveerle
              ofertas, anuncios e información que se ajusten en la mayor medida
              posible a sus expectativas y preferencias.
            </Typography>
            <Typography variant="body1" paragraph="true">
              En cada una de estas instancias, aplicaremos estas Políticas de
              Privacidad a cualquier información recibida, a menos que se
              especifique lo contrario por nosotros.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                6. PROTEGIENDO SU INFORMACIÓN PERSONAL.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Para prevenir acceso no autorizado, mantener la precisión de los
              datos y asegurar el uso correcto de su Información Personal,
              Senior Talent resguarda la Información Personal de acuerdo a
              estándares y procedimientos de seguridad establecidos, y,
              continuamente evaluamos nueva tecnología para proteger esa
              información. Senior Talent garantiza que los procesos internos
              propios de las bases de datos cumplen con las obligaciones legales
              de seguridad y confidencialidad impuestas por las leyes de cada
              país en materia de privacidad y protección de datos personales.
            </Typography>
            <Typography variant="body1" paragraph="true">
              A pesar de lo anterior, usted reconoce que los medios técnicos
              existentes que brindan seguridad no son inexpugnables y que aun
              cuando se adopten todos los resguardos razonables de seguridad es
              posible sufrir manipulaciones, destrucción y/o pérdida de
              información.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Los empleados de Senior Talent asumen estrictos compromisos de
              confidencialidad en cuanto a la Información Personal que procesan
              en el ejercicio de sus funciones.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Usted consiente, reconoce y acepta que su información personal sea
              almacenada en la jurisdicción de Senior Talent o que la misma
              pueda ser transferida, almacenada y tratada fuera de su país de
              residencia.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                7. ALMACENAMIENTO Y TRANSFERENCIA DE INFORMACIÓN.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Los Sitios son operados en la jurisdicción mostrada en la parte
              superior del Sitio a la que usted accede (la "Jurisdicción
              Relevante"). Tenga en cuenta que la información que recopilamos,
              incluida la Información Personal, será procesada y almacenada en
              la Jurisdicción Relevante y también podrá ser transferida,
              procesada y almacenada en otras jurisdicciones, donde las leyes de
              privacidad y protección de datos pueden diferir a las de su país.
              En consecuencia, los tribunales y otras autoridades de la
              Jurisdicción Relevante y en otras jurisdicciones pueden, en
              ciertas circunstancias, tener derecho a acceder a su Información
              Personal. Al utilizar los Sitios o proporcionarnos información a
              través de los Sitios, usted acepta esta transferencia,
              procesamiento y almacenamiento de su información en la
              Jurisdicción Relevante y en otras jurisdicciones, incluido para el
              tratamiento de datos personales. Independientemente de donde se
              realice el Procesamiento, los Proveedores de Servicios de Senior
              Talent están obligados por contrato a proteger la confidencialidad
              y la seguridad de su Información Personal.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Exclusión de Garantía
              </Box>
              . Senior Talent retiene su Información Personal durante el tiempo
              que sea necesario para cumplir con los propósitos para los cuales
              fue recolectada y para cumplir con las leyes aplicables. Senior
              Talent toma medidas comercialmente razonables para ayudar a
              proteger y proteger la Información Personal. Sin embargo, no se
              garantiza que la transmisión de datos a través de Internet,
              transmisión inalámbrica o almacenamiento electrónico de
              información sea 100% segura. Tenga en cuenta que no podemos
              garantizar la seguridad de ninguna información que recopilamos, y
              utiliza nuestros Sitios y nos proporciona su información bajo su
              propio riesgo.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                8. SUS DERECHOS.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Derecho a Acceder, Rectificar, Borrar y Objetar al Procesamiento
                de Información de su Información Personal.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Usted puede ejercitar sus derechos para (i) acceder a la
              Información Personal que tenemos sobre usted, (ii) rectificar la
              Información Personal en caso de que esté incompleta o sea
              inexacta, (iii) Borrar la Información Personal que está siendo
              utilizada para uno de los usos previstos en estas Políticas de
              Privacidad y (iv) objetar el procesamiento de su Información
              Personal en cualquier momento enviando una carta o un correo
              electrónico a la siguiente dirección contacto@seniortalent.cl
            </Typography>
            <Typography variant="body1" paragraph="true">
              Para atender correctamente su solicitud, sírvase adjuntar su
              nombre, apellido, dirección postal, teléfono, dirección de correo
              electrónico y cualquier otro detalle de contacto que podamos
              utilizar para verificar su identidad y contactar con usted, una
              copia de su tarjeta de identificación o identificación con foto y
              una descripción clara y precisa de la Información Personal a la
              que desea acceder, rectificar, borrar, limitar el uso o
              divulgación u oponerse al procesamiento de información, así como
              cualquier otra información útil para localizar la Información
              Personal. Todas nuestras comunicaciones con usted serán entregadas
              con los más altos niveles disponibles de reserva y
              confidencialidad. La reclamación estará sujeta a la reglamentación
              aplicable en materia de protección de datos con respecto a los
              plazos y otros requisitos.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Revocación del Consentimiento y limitación del uso y
                divulgación.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              En cualquier momento puede revocar el consentimiento dado a Senior
              Talent sobre su Información Personal contactando al equipo de
              protección de datos en la dirección de correo electrónico indicada
              anteriormente. Después de eso, eliminaremos toda la Información
              Personal almacenada en nuestras bases de datos. Sin embargo, debe
              tener en cuenta que no siempre es posible eliminar o eliminar
              completamente toda la información de nuestras bases de datos sin
              algunos datos residuales debido a copias de seguridad y otras
              razones. También puede limitar el uso o la divulgación de su
              Información Personal contactando al equipo de protección de datos
              en la dirección de correo electrónico indicada anteriormente
              indicando su deseo de retirar o limitar su información. Se le
              proporcionará una respuesta de acuerdo con el mecanismo y el
              calendario requerido por la ley aplicable.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Anular Suscripción de las Listas de Correo.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Siempre puede pedirnos que borremos su nombre de nuestras listas
              de correspondencia. En caso de que desee borrar su nombre de una
              lista de correo, o si desea actualizar su información (por
              ejemplo, cambiar su dirección), puede hacerlo en cualquier momento
              mediante notificación a Senior Talent por carta o un correo
              electrónico a la dirección indicada anteriormente.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Optar por no recibir correspondencia comercial de Senior Talent.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Si no desea recibir notificaciones por correo electrónico con
              respecto a nuestros productos o servicios, puede optar por no
              recibir la información cada vez que nos proporcione su Información
              Personal. También puede optar por no recibir ningún correo
              electrónico comercial que reciba de Senior Talent seleccionando la
              opción de "anular suscripción" o similar incluida con cada correo
              electrónico.
            </Typography>
            <Typography variant="body1" paragraph="true">
              <Box component="span" fontWeight="fontWeightBold">
                Optando por Anular el Recibir Publicidad de Retargeting.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Si no desea recibir publicidad de retargeting de Senior Talent, en
              función de su actividad en sitios de terceros, puede desactivar
              las cookies como se describe anteriormente en la sección de
              cookies y borrar el historial de su navegador.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                9. PROTECCIÓN DE LA PRIVACIDAD DE LOS NIÑOS.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              El acceso y utilización de los Sitios efectuada por menores de
              edad, de conformidad a la legislación vigente, es de
              responsabilidad de sus respectivos padres, tutores legales o
              representantes. Asimismo, Senior Talent hace presente que aquellos
              mayores de edad que, de acuerdo a la legislación vigente, tengan
              el carácter de padres, tutores legales o representantes, serán los
              responsables en caso que menores de edad que se encuentran bajo su
              tutela accedan a los Sitios, por lo que se les recomienda
              enfáticamente tomar las precauciones oportunas durante la
              navegación en los Sitios.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                10. SITIOS WEB DE TERCEROS.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Puede haber hipervínculos en los sitios a sitios web de terceros
              (incluidos, pero no limitados, entre otros, los sitios y
              aplicaciones de redes sociales). Estos Sitios Web de Terceros
              pueden utilizar sus propias cookies, web beacons y otras
              tecnologías para recopilar información de forma independiente
              sobre usted. Además, estos sitios web de terceros pueden solicitar
              Información Personal de usted. Los sitios web de terceros tienen
              prácticas separadas de privacidad y recopilación de datos. Una vez
              que abandone este sitio, Senior Talent no tiene ningún control o
              responsabilidad de ningún tipo sobre las normas de privacidad o
              sobre las actividades de recolección de datos en otros sitios. No
              hacemos ninguna representación con respecto a las políticas o
              prácticas comerciales de dichos sitios web de terceros y le
              alentamos a familiarizarse con sus políticas de privacidad antes
              de proporcionarles su Información Personal.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                11. CAMBIOS EN ESTA POLÍTICA.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              De vez en cuando, Senior Talent puede modificar esta Política de
              Privacidad. Cualquier cambio será notificado a usted si es
              requerido por la ley aplicable y será efectivo inmediatamente
              cuando la política revisada es publicada en los Sitios. Visite los
              Sitios periódicamente para informarse sobre cualquier cambio. Sin
              embargo, no utilizaremos su Información Personal de una manera
              materialmente diferente a la que se estableció en la política
              publicada en los Sitios en el momento en que se recopiló su
              Información Personal a menos que recibamos su consentimiento.
            </Typography>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="fontWeightBold">
                12. CONTACTO.
              </Box>
            </Typography>
            <Typography variant="body1" paragraph="true">
              Si tiene preguntas sobre nuestras Políticas de Privacidad,
              nuestras prácticas o sus tratos con los Sitios, desea solicitar
              acceso o corrección o actualización de su Información Personal,
              desea retirar su consentimiento (sujeto a restricciones legales o
              contractuales y aviso razonable) o tiene razones para creer que
              Senior Talent puede no haber cumplido con estas Políticas de
              Privacidad, por favor escribir a contacto@seniortalent.cl.
            </Typography>
            <Typography variant="body1" paragraph="true">
              Esta Política de Privacidad fue actualizada por última vez el [ ]
              de 2020.
            </Typography>
          </Box>
  );
}

