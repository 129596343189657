
/**
 * Check if an email as a valid format.
 * 
 * @param {*} email 
 */
export const checkEmail = (email) => {
    const validEmailRegex = RegExp(/\S+@\S+\.\S+/);
    return (validEmailRegex.test(email) && email.trim().length > 0);
}

/**
 * 
 * @param {*} name 
 */
export const checkString = (name) => {
    // console.log("name length: " + name.length);
    if(name.trim().length === 0) {
        return false;
    }

    return true;
}

/**
 * 
 * @param {*} address 
 */
export const checkAddress = (address) => {
    // console.log("address length: " + address.length);
    if (address.trim().length === 0) {
        return false;
    }

    return true;
}

/**
 * 
 * @param {*} name 
 */
export const checkName = (name) => {
    // console.log("name length: " + name.length);
    if(name.trim().length === 0) {
        return false;
    }

    const validFullNameRegex = RegExp(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/);
    return validFullNameRegex.test(name);
}

/**
 * 
 * @param {*} phone
 */
export const checkPhone = (cellphone) => {
    const validCellPhoneRegex = RegExp(/^(\s?)[987654321]\d{7}$/);
    return (validCellPhoneRegex.test(cellphone) && cellphone.trim().length > 0);
}

/**
 * 
 * @param {*} rut 
 */
export const checkRut = (fullRut) => {
    const rut = fullRut.toLowerCase().replace(".", "").split("-");
    const dv = getLastDigitalRut(rut[0]);

    // console.log("rut:" + rut[0] + "-" + rut[1])
    // console.log("dv: " + dv);

    return (dv == rut[1] && fullRut.trim().length > 0);

}

/**
 * 
 * @param {*} bodyRut 
 */
const getLastDigitalRut = (bodyRut) => {
    let m = 0;
    let s = 1;

    for(;bodyRut;bodyRut=Math.floor(bodyRut/10)) {
        s=(s+bodyRut%10*(9-m++%6))%11;
    }

    return s ? s-1 : 'k';
}

/**
 * 
 * @param {*} url
 */
export const checkWebsite = (value) => {
    const linkedInProfileURLRegExp = '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})';
    return !!isRegexExactMatch(value, linkedInProfileURLRegExp);
};

// Generic RegEx exact match validator
const isRegexExactMatch = (value, regexp) => {
    const res = value.match(regexp);
    return res && res[0] && res[0] === res.input;
};

/**
 * 
 * @param {*} region 
 */
export const checkRegion = (region) => {
    // console.log("checkRegion:" + region.length);

    return (region.length > -1);
}

/**
 * 
 * @param {*} commune 
 */
export const checkCommune = (commune) => {
    // // console.log("checkDocuments: " + documents.length);
    
    return (commune.length > -1);
}

/**
 * 
 * @param {*} industries 
 */
 export const checkIndustries = (industries) => {
    return (industries.length > 0);
}

/**
 * 
 * @param {*} other 
 */
 export const checkOtherIndustry = (other) => {
    return true;
}

/**
 * 
 * @param {*} employees 
 */
export const checkEmployees = (employees) => {
    return (employees.length > 1);
}

/**
 * 
 * @param {*} billing 
 */
export const checkBilling = (billing) => {
    return (billing.length > 1);
}

/**
 * 
 * @param {*} password 
 */
export const checkPassword = (password) => {
    return password.trim().length > 5;
}

/**
 * 
 * @param {*} repeat 
 */
export const checkRepeat = (repeat) => {
    return repeat.trim().length > 0;
}