import React, { useState } from 'react';
import { makeStyles, withStyles, TextField } from '@material-ui/core';

const GCP_UPLOAD_FUNCTION_URL = process.env.REACT_APP_GCP_UPLOAD_FUNCTION_URL || 'https://dummy-url/';

export const useForm = (initialFieldValues, initialErrorsValues, validate, formatter) => {
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState(initialErrorsValues)

    const handleInputChange = e => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        });

        setErrors({
          ...errors,
          [name]: !validate[name](value)
        })
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormControl-root": {
          width: '96%',
          margin: theme.spacing(1),
          "background-color": '#FFF',
        },
        "& .MuiPaper-root": {
          "background-color": '#FFF',
        },
        "& .MuiFormHelperText-root.Mui-error": {
          "background-color": '#FFF',
          "margin-left": '0',
          "margin-right": '0',
          "margin-top": '0',
        },
    },
    input: {
      display: 'none',
    }
}));

export const Form = (props) => {
    const classes = useStyles();

    return(
        <form className={classes.root}>
            {props.children}
        </form>
    )
}
