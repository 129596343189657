import firebase from 'firebase/app';
import "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_STORAGE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_STORAGE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_STORAGE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_STORAGE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_STORAGE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_STORAGE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_STORAGE_MEASUREMENTID
};

console.log("PROF PROCES_ENV: ",process.env);

// Initialize Firebase Professional Form
const base = firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default base;
