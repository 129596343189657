import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Grid,
  Box,
  Divider,
  TextField,
  Paper,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
  Select,
  Input,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Link,
  Dialog,
  DialogTitle,
  Checkbox
} from '@material-ui/core'
import communeValues from './datasource/communes.json'
import industryValues from './datasource/industries.json'
import employeesValues from './datasource/employees.json'
import billingValues from './datasource/billing.json'
import { useForm, Form } from './hooks/useForm'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import {
  checkRut,
  checkBilling,
  checkCommune,
  checkEmail,
  checkIndustries,
  checkPassword,
  checkPhone,
  checkRegion,
  checkWebsite,
  checkEmployees,
  checkString,
  checkAddress,
  checkOtherIndustry
} from './helpers/validate'
import PropTypes from 'prop-types'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FONT_FAMILY } from './helpers/constants';
import { Terms } from './components/terms'
import { Privacity } from './components/privacity'

console.log("COMP PROCES_ENV: ",process.env);

const SERVICE_COMPANY_URL = process.env.REACT_APP_SERVICE_COMPANY_URL ||
  'https://us-central1-vivid-motif-279523.cloudfunctions.net/companies'

const initialFieldValues = {
  rut: '',
  businessname: '',
  commercialcode: '',
  website: '',
  name: '',
  phone: '',
  email: '',
  region: '',
  commune: '',
  address: '',
  password: '',
  description: '',
  industries: [],
  employees: '',
  billing: '',
  otherindustry: ''
}

const initialErrorValues = {
  rut: false,
  businessname: false,
  commercialcode: false,
  website: false,
  name: false,
  phone: false,
  email: false,
  region: false,
  commune: false,
  address: false,
  password: false,
  description: false,
  industries: false,
  employees: false,
  billing: false,
  otherindustry: false
}

const schemaValidation = {
  rut: checkRut,
  businessname: checkString,
  commercialcode: checkString,
  website: checkWebsite,
  name: checkString,
  phone: checkPhone,
  email: checkEmail,
  region: checkRegion,
  commune: checkCommune,
  address: checkAddress,
  password: checkPassword,
  description: checkString,
  industries: checkIndustries,
  employees: checkEmployees,
  billing: checkBilling,
  otherindustry: checkOtherIndustry
}

const style = {
  labelForm: {
    fontFamily: FONT_FAMILY,
    color: '#1A202C',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  subLabelForm: {
    fontFamily: FONT_FAMILY,
    color: '#000000',
    fontSize: '12px'
  },
  inputForm: {
    fontFamily: FONT_FAMILY,
    color: '#CBD5E0'
  },
  buttonSubmitEnabled: {
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  },
  buttonSubmitDisabled: {
    fontFamily: FONT_FAMILY,
    background: '#808080',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  }
}

const useStyles = makeStyles({
  customInputLabel: {
    "& legend": {
      visibility: "visible"
    }
  }
});

const defaultLabelProps = {
  style: { fontFamily: FONT_FAMILY, fontSize: '34px', color: '#3155A4' }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const CompanyForm = ({ readonly, title, data, handlerCloseForm }) => {
  const dataCommune = data ? data.commune : ''
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    data ? { ...data, commune: '' } : initialFieldValues,
    initialErrorValues,
    schemaValidation
  )

  const classes = useStyles();

  const [communeList, setCommuneList] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [communeUpdated, setCommuneUpdated] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  /**
   * Define form mode (Create or Edit)
   */
  useEffect(() => {
    if (data) {
      setEditMode(true)
    }
  }, [])

  /**
   * Related selects (region / commune)
   */
  useEffect(() => {
    setValues({ ...values, commune: '' })
    if (values.region.length > 0) {
      setCommuneList(communeValues[values.region])
    } else {
      setCommuneList([])
    }

    if (!communeUpdated) {
      setValues({ ...values, commune: dataCommune })
      setCommuneUpdated(true)
    }
  }, [values.region])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  /**
   *
   * @param {*} data
   */
  const validateForm = () => {
    let result = true
    let newErrorState = {}

    Object.keys(initialFieldValues).forEach((field) => {
      let checkField = !schemaValidation[field](values[field])
      newErrorState[field] = checkField

      if (checkField) {
        result = !checkField
      }
    })

    setErrors(newErrorState)

    return result
  }

  /**
   *
   */
  const sendFormHandler = () => {
    if (!validateForm()) {
      alert('Formulario con errores, por favor revise los valores ingresados.')
      return false
    }

    const dataForm = Object.assign({}, values)

    // console.log("DataForm:");
    // console.log(SERVICE_COMPANY_URL + (editMode ? "/" + dataForm.id : ""));
    // console.log(dataForm);

    axios({
      method: editMode ? 'put' : 'post',
      url: SERVICE_COMPANY_URL + (editMode ? '/' + dataForm.id : ''),
      data: editMode
        ? { ...dataForm, id: undefined, rut: undefined }
        : dataForm,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((resp) => {
        // console.log(`(resp.status === editMode ? 200 : 201)) <> (${resp.status} === ${editMode} ? 200 : 201`);
        if (resp.status === editMode ? 200 : 201) {
          alert(
            `Su registro se ha ${editMode
              ? 'actualizado correctamente'
              : 'enviado correctamente, por favor revise su correo.'
            }`
          )
        }

        if (!editMode) {
          setValues(initialFieldValues)
        }

        /**
         * Define a closure function after submit an update successfully
         */
        handlerCloseForm(
          editMode ? dataForm : { ...dataForm, id: resp.data.id }
        )
      })
      .catch((err) => {
        if (err.response.status === 409) {
          alert(`El rut ${dataForm.rut} ya se encuentra en nuestros registros.`)
        } else {
          alert(
            'Tenemos problemas con el servicio, por favor reintente más tarde.'
          )
        }
      })
  }

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [checked, setChecked] = useState(false);


  useEffect(() => {
    setOpenDialog(dialogType.length > 0);
  }, [dialogType]);

  const handleShowDialog = (e) => {
    const { currentTarget } = e;
    const dialogType = currentTarget.getAttribute('data');

    setDialogType(dialogType);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Paper variant='outlined'>
      <Form>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setDialogType('')}
        aria-labelledby="form-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ textAlign: 'center' }}>
            <InputLabel
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: '34px',
                color: '#3155A4',
              }}
            >
              {dialogType == 'terminos' ? 'TÉRMINOS Y CONDICIONES DE USO' : 'POLÍTICAS DE PRIVACIDAD'}
            </InputLabel>
          </div>
        </DialogTitle>
        {dialogType == 'terminos' ? (
          <Terms></Terms>
        ) : (
        <Privacity></Privacity>
        )}
      </Dialog>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <Box mb={2}>
              <InputLabel {...defaultLabelProps}> {title} </InputLabel>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Rut
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Ingresa tu RUT sin puntos y con guión</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={style.inputForm}
                    disabled={editMode}
                    name='rut'
                    variant='outlined'
                    label=''
                    placeholder='78xxxxxxx'
                    value={values.rut}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.rut}
                    helperText={
                      errors.rut ? 'Por favor ingrese un rut válido.' : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Razón Social
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Empresa contratante del servicio</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={style.inputForm}
                    name='businessname'
                    variant='outlined'
                    label=''
                    placeholder='Caperucita Roja Ltda'
                    value={values.businessname}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.businessname}
                    helperText={
                      errors.businessname
                        ? 'Por favor ingrese Razón Social.'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Giro Comercial
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Actividad a la cual se dedica</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='commercialcode'
                    variant='outlined'
                    label=''
                    value={values.commercialcode}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.commercialcode}
                    helperText={
                      errors.commercialcode
                        ? 'Por favor ingrese su Giro Comercial.'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Página Web
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='website'
                    variant='outlined'
                    label=''
                    placeholder='www.caperucitaroja.cl'
                    value={values.website}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.website}
                    helperText={
                      errors.website ? 'Por favor ingrese su página web.' : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Nombre Contacto
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Persona responsable de administrar las publicaciones</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='name'
                    variant='outlined'
                    label=''
                    placeholder='Juan Perez Mujica'
                    value={values.name}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.name}
                    helperText={
                      errors.name
                        ? 'Por favor ingrese su nombre de contacto.'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Teléfono Contacto
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='phone'
                    variant='outlined'
                    label=''
                    placeholder='xxxxxx'
                    value={values.phone}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.phone}
                    helperText={
                      errors.phone
                        ? 'Por favor ingrese su teléfono de contacto de 8 dígitos.'
                        : ''
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>+56 9</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    E-mail Contacto
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    disabled={editMode}
                    name='email'
                    variant='outlined'
                    label=''
                    value={values.email}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.email}
                    helperText={
                      errors.email
                        ? 'Por favor ingrese su correo de contacto.'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Región
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant='outlined' error={errors.region}>
                    <Select
                      labelId='region-select-label'
                      name='region'
                      value={values.region}
                      onChange={handleInputChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value=''>
                        <em>Seleccionar</em>
                      </MenuItem>
                      {Object.keys(communeValues).map((region) => (
                        <MenuItem
                          key={Object.keys(communeValues).indexOf(region)}
                          value={region}
                        >
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.region ? (
                      <FormHelperText>
                        Debe seleccionar una región
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Comuna
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant='outlined' error={errors.commune}>
                    <Select
                      labelId='commune-select-label'
                      name='commune'
                      value={values.commune}
                      onChange={handleInputChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value=''>
                        <em>Seleccionar</em>
                      </MenuItem>
                      {communeList.map((commune) => (
                        <MenuItem key={commune} value={commune}>
                          {commune}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.commune ? (
                      <FormHelperText>
                        Debe seleccionar una comuna
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Dirección
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Casa matriz</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='address'
                    variant='outlined'
                    label=''
                    value={values.address}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.address}
                    helperText={
                      errors.address ? 'Por favor ingrese su dirección.' : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Password
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Ingresa una password de al menos 6 caracteres</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    className={clsx({ width: '25ch' })}
                    error={errors.industries}
                  >
                    <OutlinedInput
                      classes={{ notchedOutline: classes.customInputLabel }}
                      name='password'
                      notched
                      variant='outlined'
                      label=''
                      value={values.password}
                      onChange={handleInputChange}
                      size='small'
                      type={showPassword ? 'text' : 'password'}
                      error={errors.password}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.password ? (
                      <FormHelperText>
                        Su password debe ser al menos de 6 caracteres.
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Descripción General
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Permite a la comunidad de Senior Talent, conocer a que se dedica tu empresa</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name='description'
                    variant='outlined'
                    label=''
                    value={values.description}
                    onChange={handleInputChange}
                    size='small'
                    error={errors.description}
                    helperText={
                      errors.description ? 'Por favor ingrese su descripción' : ''
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Industria
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Permite a la comunidad de Senior Talent, conocer la(s) industria(s) donde se desempeña</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant='outlined' error={errors.industries}>
                    <Select
                      labelId='industry-checkbox-label'
                      name='industries'
                      multiple
                      value={values.industries}
                      onChange={handleInputChange}
                      input={<OutlinedInput />}
                      renderValue={(value) => value.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {industryValues.map((industry) => (
                        <MenuItem
                          key={industryValues.indexOf(industry)}
                          value={industry}
                        >
                          {industry}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.industries ? (
                      <FormHelperText>
                        Debe seleccionar al menos una industria
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                  {values.industries.includes('Otra') ? (
                    <TextField
                      name='otherindustry'
                      variant='outlined'
                      label='Otra Industria'
                      value={'otherindustry' in values ? values.otherindustry : ''}
                      onChange={handleInputChange}
                      size='small'
                      error={errors.otherindustry}
                      helperText={
                        errors.otherindustry
                          ? 'Por favor ingrese su otra industria.'
                          : ''
                      }
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Cantidad de Empleados
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Permite a la comunidad de Senior Talent, tener una referencia de tamaño de tu empresa</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant='outlined' error={errors.employees}>
                    <Select
                      labelId='employees-select-label'
                      name='employees'
                      value={values.employees}
                      onChange={handleInputChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value=''>
                        <em>Seleccionar</em>
                      </MenuItem>
                      {employeesValues.map((employee) => (
                        <MenuItem
                          key={employeesValues.indexOf(employee)}
                          value={employee}
                        >
                          {employee}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.employees ? (
                      <FormHelperText>
                        Debe seleccionar un rango de empleados
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box m={2}>
              <Grid
                m={4}
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={4}>
                  <Typography style={style.labelForm} component='h5'>
                    Facturación anual
                  </Typography>
                  <Typography style={style.subLabelForm} variant="subtitle1">Permite a la comunidad de Senior Talent, tener una referencia de volumen de facturación expresada en pesos chilenos.</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant='outlined' error={errors.employees}>
                    <Select
                      labelId='billing-select-label'
                      name='billing'
                      value={values.billing}
                      onChange={handleInputChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value=''>
                        <em>Seleccionar</em>
                      </MenuItem>
                      {billingValues.map((billing) => (
                        <MenuItem
                          key={billingValues.indexOf(billing)}
                          value={billing}
                        >
                          {billing}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.billing ? (
                      <FormHelperText>
                        Debe seleccionar un rango de ingreso anual
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Divider />

              <Grid container justify='flex-end'>
              <Box m={4} display="inline">
                <Typography variant='subtitle2'>
                <Checkbox color="primary" onChange={handleChange} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />Yo acepto&nbsp;
                  <Link data="politicas" onClick={handleShowDialog}>
                    Políticas de Privacidad
                  </Link>
                  &nbsp;y&nbsp;
                  <Link data="terminos" onClick={handleShowDialog}>
                    Términos y Condiciones
                  </Link>
                  &nbsp;de uso.
                </Typography>
              </Box>
              <Box m={4}>
                <Button
                disabled={!checked}
                style={!checked ? style.buttonSubmitDisabled : style.buttonSubmitEnabled}
                  variant='contained'
                  onClick={readonly ? handlerCloseForm : sendFormHandler}
                >
                  {readonly ? 'Cerrar' : editMode ? 'Guardar cambios' : 'Enviar'}
                </Button>
                </Box>
              </Grid>


          </Grid>
        </Grid>
      </Form>
    </Paper>
  )
}

CompanyForm.propTypes = {
  readonly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
    businessname: PropTypes.string.isRequired,
    commercialcode: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    commune: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    industries: PropTypes.arrayOf(PropTypes.string).isRequired,
    employees: PropTypes.string.isRequired,
    billing: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired
    ]).isRequired
  }),
  handlerCloseForm: PropTypes.func
}
