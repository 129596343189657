import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';

const theme = createMuiTheme({
  overrides: {
    MuiGrid: {
      'spacing-xs-1': {
          width: 'auto',
          margin: '0px',
      },
    },
  },
});

const Structure = makeStyles((theme) => ({
    
    root: {
      flexGrow: 1,
      height: '100vh',
      minHeight: '100vh',
      scrollBehavior: 'smooth',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      height: '80vh',
      minHeight: '80vh',
      display: 'block',
      color: theme.palette.text.secondary,
    },
    title: {
      fontSize: '2.4em',
      fontWeight: 300,
    },
    subtitle: {
      fontSize: '25px',
      fontWeight: 100,
    },
    serviceTabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      offset: theme.mixins.toolbar,
    },
  }));

// const Structure = useStyles();

export default Structure;