import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';


const BasicTable = (props) => {
    const {
        headData,
        tableData,
        tableCellProcess,
        setTableData
    } = props;


    useEffect(() => {
        // // console.log("BasicTable component");
        // // console.log(tableData)
    }, []);

    /**
     * Create Head row cells
     * @param {*} headData
     */
    const createTableHeadRowCells = () => {
        // // console.log('headData: ' + headData);
        return headData.map((head) => (
            <TableCell key={uuidv4()} style={{fontFamily:FONT_FAMILY, fontWeight: "bolder"}}>{head.value}</TableCell>
        ))
    }

    /**
     * Remove table entry from body
     * @param {*} e
     */
    const handlerDeleteClicked = e => {
        const { currentTarget } = e;
        const dataReference = currentTarget.getAttribute('data');

        const newTableData = new Map(tableData);

        newTableData.delete(dataReference);

        setTableData(new Map(newTableData));

    }

    /**
     * Create table data based on headers values
     */
    const createTableData = () => {
        // // console.log('createTableData => tableData: ');
        // // console.log(tableData);
        // // console.log('headData: ' + headData);

        return [...tableData.keys()].map((tableDataKey) => (
            <TableRow key={uuidv4()}>
                {
                    headData.map((head) => (
                        <TableCell style={{fontFamily:FONT_FAMILY}} key={uuidv4()}>
                            {tableCellProcess(head.key, tableDataKey, tableData.get(tableDataKey))}
                        </TableCell>
                    ))
                }
                <TableCell key={uuidv4()}>
                    <IconButton style={{color:THEME_COLOR.navyDark1}} data={tableDataKey}  aria-label="upload picture" component="span" onClick={handlerDeleteClicked}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ))
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {createTableHeadRowCells()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {createTableData()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BasicTable;
