import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import {
  Paper,
  Grid,
  Typography,
  MenuList,
  MenuItem,
  Box,
  Hidden,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
} from '@material-ui/core';
import knowledgeIcon from '../../images/conocimiento.svg';
import disciplineIcon from '../../images/disciplina.svg';
import autonomyIcon from '../../images/autonomia.svg';
import whoaretheyImage from '../../images/whoarethey.png';
import { ProfessionalForm } from 'lib-professional-form';
import { CompanyForm } from 'lib-company-form';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';

const Customer = () => {
  const classes = Structure();

  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  /**
   * Based in selected element it will show/hide edit dialog.
   */
  useEffect(() => {
    setOpenForm(formType.length > 0);
  }, [formType]);

  const handleShowForm = (e) => {
    const { currentTarget } = e;
    const formType = currentTarget.getAttribute('data');

    setFormType(formType);

    // console.log(`Abrir form of type ${formType} `);
  };

   //choose the screen size
   const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const styleMobile = {
    boxes: { marginLeft: '40px', marginRight: '40px' },
    content:{ flexGrow: 1, height: '93vh', minHeight: '93vh' },
    button:{
      color: 'white',
      backgroundColor: '#FD513B',
      height: '48px',
      width: '197px',
      borderRadius: '40px',
      padding: '10px',
      margin: '0px 10px 0px 10px',
      marginLeft: '27vw',
    }
  };
  const style = {
    boxes: {},
    content:{ flexGrow: 1, height: '85vh', minHeight: '85vh' },
    button:{
      color: 'white',
      backgroundColor: '#FD513B',
      height: '48px',
      width: '197px',
      borderRadius: '40px',
      padding: '10px',
      margin: '0px 10px 0px 10px',
      marginLeft: '15vw',
    }
  };

  return (
    <Grid container spacing={1} style={{ paddingTop: '40px' }}>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setFormType('')}
        aria-labelledby="form-dialog-title"
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ textAlign: 'center' }}>
            <InputLabel
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: '34px',
                color: '#3155A4',
              }}
            >
              Registro para{' '}
              {formType == 'Profesional' ? 'profesionales' : 'empresas'}
            </InputLabel>
          </div>
        </DialogTitle>
        {formType == 'Profesional' ? (
          <ProfessionalForm
            title=""
            handlerCloseForm={() => setOpenForm(false)}
            message={true}
          />
        ) : (
          <CompanyForm title="" handlerCloseForm={() => setOpenForm(false)} />
        )}
      </Dialog>

      <Grid item md={6}>
        <Hidden smDown>
          <div
            style={{
              flexGrow: 1,
              height: '85vh',
              minHeight: '85vh',
              textAlign: 'center',
            }}
          >
            <img
              src={whoaretheyImage}
              alt="seniorwoman"
              style={{ height: '80vh' }}
            />
          </div>
        </Hidden>
      </Grid>
      <Grid item sm={12} md={6}>
        <div style={isMobile ? styleMobile.content : style.content}>
          <Box display="flex" height="100%">
            <Box  style={isMobile ? styleMobile.boxes : style.boxes}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: FONT_FAMILY,
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#3155A4',
                }}
              >
                ¿QUIÉNES SON NUESTROS SENIORS TALENT?
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: FONT_FAMILY,
                  marginRight: '40px',
                  marginTop: '1px',
                  marginBottom: '0px',
                  fontWeight:'bold'
                }}
              >
                El talento senior representa en la actualidad un activo vital y
                del que no podemos prescindir en nuestro país.
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: FONT_FAMILY,
                  marginRight: '40px',
                  marginTop: '20px',
                  marginBottom: '40px',
                }}
              >
                Profesionales sobre 50 años que buscan generar espacios de
                colaboración y potenciamiento en nuestra comunidad, compartiendo
                experiencias, networking y mejores practicas, para seguir
                desarrollando sus carreraras profesionales y con ello aportar al
                desarrollo económico y social del país.
              </Typography>
              <ul style={{listStyle:'none'}}>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={knowledgeIcon} alt="conocimiento" />
                    </Box>
                    <Box paddingTop={2}>
                      <Typography variant="subtitle2">
                        Amplio conocimiento en sus rubros y areas de expertise.
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={autonomyIcon} alt="autonomía" />
                    </Box>
                    <Box paddingTop={2.5}>
                      <Typography variant="subtitle2">
                        Autonomía e independencia para llevar adelante su
                        trabajo.
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={disciplineIcon} alt="disciplina" />
                    </Box>
                    <Box paddingTop={2}>
                      <Typography variant="subtitle2">
                        Disciplina y cultura de esfuerzo desarrollados en sus
                        vastas carreras.
                      </Typography>
                    </Box>
                  </Box>
                </li>
                </ul>
              <Button
                variant="contained"
                style={isMobile ? styleMobile.button : style.button}
                data="Profesional"
                onClick={handleShowForm}
              >
                SOY PROFESIONAL
              </Button>
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Customer;
