import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import { Grid, Typography, Box } from '@material-ui/core';
import unEmployeeImage from '../../images/desempleado.jpeg';
import ofertImage from '../../images/oferta.png';
import employeeImage from '../../images/empleado.png';
import { FONT_FAMILY } from '../../helpers/constants';

const Market = () => {
  const classes = Structure();

  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const styleMobile = {
    boxes: {
        flexGrow: 1,
        height: '78vh',
        minHeight: '78vh',
        textAlign: 'center',
      },
  };
  const style = {
    boxes: {
        flexGrow: 1,
        height: '85vh',
        minHeight: '85vh',
        textAlign: 'center',
      },
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ backgroundColor: '#FFFFFF', paddingTop: '40px' }}
    >
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h6"
            style={{
              fontFamily: FONT_FAMILY,
              fontSize: '34px',
              fontWeight: 'bold',
              color: '#3155A4',
            }}
          >
            Contexto de mercado
          </Typography>
        </div>
      </Grid>
      <Grid container spacing={1} style={{ backgroundColor: '#FFFFFF' }}>
        <Grid item xs={12} md={4} style={{ backgroundColor: '#F3F6F7' }}>
          <div
            style={isMobile ? styleMobile.boxes : style.boxes}
          >
            <Box>
              <Box>
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '34px',
                    fontWeight: 'bolder',
                    color: '#FD513B',
                  }}
                >
                  + 250.000
                </Typography>
              </Box>
              <Box p={1}>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: FONT_FAMILY, fontSize: '16px' }}
                >
                  Mayores de 50 años con estudios superiores desempleados.
                </Typography>
              </Box>
              <Box p={1}>
                <img src={unEmployeeImage} alt="desempleados" />
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            style={isMobile ? styleMobile.boxes : style.boxes}
          >
            <Box>
              <Box p={1}>
                <img src={ofertImage} alt="Oferta Laboral" />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '34px',
                    fontWeight: 'bolder',
                    color: '#FD513B',
                  }}
                >
                  0,5%
                </Typography>
              </Box>
              <Box p={1}>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: FONT_FAMILY, fontSize: '16px' }}
                >
                  De la oferta laboral es dirigidas a profesionales mayores a 55
                  años!
                </Typography>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ backgroundColor: '#F3F6F7' }}>
          <div
            style={isMobile ? styleMobile.boxes : style.boxes}
          >
            <Box>
              <Box>
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '34px',
                    fontWeight: 'bolder',
                    color: '#FD513B',
                  }}
                >
                  + 50%
                </Typography>
              </Box>
              <Box p={1}>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: FONT_FAMILY, fontSize: '16px' }}
                >
                  De las empresas en Chile son PYMES y concentran sobre el 60%
                  del empleo, aportando un 20% del PIB.
                </Typography>
              </Box>
              <Box p={1}>
                <img src={employeeImage} alt="empleados" />
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Market;
