import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

export const Terms = () => {
  return (
    <Box m={6} flex justifyContent="space-between" align="justify">
    <Typography variant="body1" paragraph="true">
      Estos términos y condiciones (los “
      <Box component="span" fontWeight="fontWeightBold">
        Términos y Condiciones
      </Box>
      ”) aplicables al usuario, regulan el acceso y/o uso de los
      contenidos y servicios (en adelante, los “
      <Box component="span" fontWeight="fontWeightBold">
        Servicios
      </Box>
      ”), del sitio web www.seniortalent.cl (el “
      <Box component="span" fontWeight="fontWeightBold">
        Sitio
      </Box>
      ”), de propiedad y controlado por Senior Talent SpA (“
      <Box component="span" fontWeight="fontWeightBold">
        Senior Talent
      </Box>
      ”). Para obtener más información sobre Senior Talent y sus
      Servicios, visite{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.seniortalent.cl"
      >
        www.seniortalent.cl
      </Link>
      .
    </Typography>
    <Typography variant="body1" paragraph="true">
      Desde el momento en que los usuarios accedan al Sitio, se
      sujetarán a los siguientes términos y condiciones. El acceso y/o
      uso del Sitio atribuye a quien accede la condición de usuario,
      aceptando, desde ese mismo momento, plenamente y sin reserva
      alguna, los presentes Términos y Condiciones, así como las
      condiciones particulares que, en su caso, complementen, modifiquen
      o sustituyan los Términos y Condiciones, cuando así suceda, en
      relación con todos los servicios y contenidos del Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      En caso de no estar de acuerdo con los Términos y Condiciones, por
      favor, abandone el Sitio y absténgase de utilizar los Servicios
      que se ofrecen. La utilización por el usuario del Sitio se
      entenderá como aceptación plena y sin reservas de los Términos y
      Condiciones aquí establecidos.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Al hacer clic en el cuadro correspondiente en la página de
      registro de estos Términos y Condiciones, acepta estar sujeto a
      este instrumento.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        1. DEFINICIONES.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      “
      <Box component="span" fontWeight="fontWeightBold">
        Contenido
      </Box>
      ”: todo texto, información, gráficos, imágenes, logos, marcas,
      programas de computación, bases de datos, diseños, arquitectura
      funcional, códigos, y cualquier otro material al cual se tiene
      acceso al usar el Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      "
      <Box component="span" fontWeight="fontWeightBold">
        Parte Relacionada
      </Box>
      ": cualquier afiliado, entidad que controle Senior Talent y/o
      filiales de Senior Talent.
    </Typography>
    <Typography variant="body1" paragraph="true">
      "
      <Box component="span" fontWeight="fontWeightBold">
        Proveedores de Servicios
      </Box>
      ": cualquier proveedor de servicios de terceros, que no sea una
      Parte Relacionada, que pueda realizar ciertos servicios para
      Senior Talent, tales como alojamiento de sitios web, cumplimiento
      de pedidos, entrega de materiales promocionales y ofertas de
      productos de Senior Talent, compensación y procesamiento de
      crédito y débito, pagos con tarjeta o realizar otros servicios en
      nombre de Senior Talent.
    </Typography>
    <Typography variant="body1" paragraph="true">
      "
      <Box component="span" fontWeight="fontWeightBold">
        Sitios Web de Terceros
      </Box>
      ": significa otros sitios web o ubicaciones que son operados y
      controlados por terceros que no sean Senior Talent, sus Partes
      Relacionadas y/o sus Filiales.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        “Usuario” o “Usuarios”
      </Box>
      : cualquier persona que accede y/o utiliza el Sitio controlado por
      Senior Talent.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        2. ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      El acceso al Sitio, en forma directa o indirecta, su uso y/o la
      descarga de información contenida en este suponen que el usuario
      acepta los presentes términos y condiciones en todas sus partes.
      El uso que el usuario haga del Sitio y de cualquier aplicación,
      software, datos, productos, promociones, y cualquier otro servicio
      que Senior Talent le brinde en, o a través del Sitio estarán
      sujetos a los términos del acuerdo legal entre el usuario y Senior
      Talent, como estipula la cláusula 18.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        3. MODIFICACIÓN DE LA INFORMACIÓN.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva la facultad y el derecho de actualizar,
      modificar o eliminar, en parte o en todo, y sin expresión de
      causa, cualquier contenido o información publicada en el Sitio
      según lo estime conveniente, pudiendo hacer uso de tal facultad en
      cualquier momento y sin previo aviso.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        4. ACCESO Y USO DEL SITIO. PROHIBICIONES.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Acceso al Sitio
      </Box>
      . El acceso del Sitio no exige la previa suscripción o registro
      del usuario. Sin embargo, para la utilización de algunos de los
      Servicios ofrecidos a través del Sitio se requerirá la suscripción
      o registro del usuario y/o el pago de un precio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva el derecho de negar o poner fin al acceso
      del usuario al Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Uso del Sitio
      </Box>
      . El usuario se compromete a utilizar el Sitio y los Servicios
      diligentemente y de conformidad con estas Términos y Condiciones,
      las leyes aplicables y con la moral y buenas costumbres. El Sitio
      sólo podrá ser utilizado con fines lícitos, para acceder a
      información referida a los Servicios disponibles a través del
      mismo.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Prohibiciones.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      1. El usuario se obliga a abstenerse de utilizar el Sitio con
      fines o efectos ilícitos, contrarios a lo establecido en estos
      Términos y Condiciones, lesivos de los derechos e intereses de
      terceros, o que de cualquier forma puedan dañar, inutilizar,
      sobrecargar o deteriorar el adecuado funcionamiento del Sitio o
      impedir la normal utilización del Sitio por parte de los usuarios.
    </Typography>
    <Typography variant="body1" paragraph="true">
      2. Los usuarios tienen prohibido utilizar el Sitio para
      transmitir, distribuir, almacenar o destruir material violando la
      normativa vigente, de forma que se infrinjan derechos de terceros
      o se viole la confidencialidad, honor, privacidad, imagen u otros
      derechos personales de otras personas.
    </Typography>
    <Typography variant="body1" paragraph="true">
      3. El usuario tiene prohibido realizar usos comerciales no
      autorizados del Sitio Web.
    </Typography>
    <Typography variant="body1" paragraph="true">
      4. Los usuarios tienen prohibido violar o intentar violar la
      seguridad del Sitio, incluyendo pero no limitándose a: (i) el
      acceso a datos que no estén destinados a tal usuario o entrar en
      un servidor o cuenta cuyo acceso no está autorizado al usuario;
      (ii) evaluar o probar la vulnerabilidad de un sistema o red, o
      violar las medidas de seguridad o identificación sin la adecuada
      autorización; (iii) intentar impedir el Servicio a cualquier
      usuario, anfitrión o red, incluyendo, pero sin limitación,
      mediante el envío de virus al Sitio, o mediante saturación o
      ataques de denegación de Servicio; (iv) enviar correos no pedidos,
      incluyendo promociones y/o publicidad de productos o servicios;
      (v) falsificar cualquier cabecera de paquete TCP/IP o cualquier
      parte de la información de la cabecera de cualquier correo
      electrónico o en mensajes de foros de debate; (vi) usar ningún
      robot, “spider” u otro dispositivo automático, proceso o medio
      para acceder a esta web para cualquier propósito, incluyendo el
      scraping, la minería de datos, la monitorización o copia de
      cualquier material de esta web; y (vii) cualquier otro intento de
      interferir en el correcto funcionamiento del sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      5. Senior Talent prohíbe específicamente cualquier utilización del
      Sitio para: (i) Anunciar datos biográficos incompletos, falsos o
      inexactos; (ii) Registrar más de una cuenta correspondiente a un
      mismo usuario; (iii) Revelar o compartir su contraseña con
      terceras personas, o usar su contraseña para propósitos no
      autorizados; (iv) El uso o intento de uso de cualquier máquina,
      software, herramienta, agente u otro mecanismo para navegar o
      buscar en este Sitio que sean distintos a las herramientas de
      búsqueda provistos por Senior Talent en este Sitio; (v) Intentar
      descifrar, descompilar u obtener el código fuente de cualquier
      programa de software de este Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Infracciones
      </Box>
      . Las violaciones de la seguridad del sistema o de la red
      constituyen delitos penales y pueden derivar en responsabilidades
      civiles. Senior Talent investigará los casos de violaciones a la
      seguridad del sistema, pudiendo dirigirse a la autoridad judicial
      o administrativa competente a los efectos de perseguir a los
      usuarios involucrados en tales violaciones.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        5. PAGO.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Para el acceso a algunos de los Servicios ofrecidos a través del
      Sitio, el usuario deberá realizar pagos para la suscripción de
      tales Servicios. El usuario deberá cumplir con sus obligaciones de
      pago y acepta que almacenemos su información de pago.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario entiende y acepta que: (i) los precios pueden estar
      sujetos a tasas o impuestos adicionales; (ii) la compra puede
      estar sujeta a comisiones de cambio o a diferencias de precios en
      función de tu ubicación (por ejemplo, tipos de cambio); (iii) se
      podrán almacenar y continuar utilizando la forma de pago (como una
      tarjeta de crédito) para fines de facturación, incluso después de
      haber caducado, para evitar la interrupción de los Servicios y
      utilizarla para pagar otros Servicios que el usuario pueda
      adquirir; (iv) si adquiere una suscripción, se cargarán de forma
      automática a su forma de pago al comienzo de cada periodo de
      suscripción las tasas e impuestos aplicables a ese periodo; (v)
      todas las compras de Servicios se rigen por la política de
      reembolso de Senior Talent [insertat link]; (vi) podemos calcular
      los impuestos aplicables en función de la información de
      facturación que nos proporciones en el momento de la compra.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Se puede obtener una copia de la factura a través de la
      configuración de la cuenta en Senior Talent [insertat link].
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        6. CANALES DE COMUNICACIÓN.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Si el usuario tiene alguna pregunta o desea comunicarse con Senior
      Talent, puede escribir a contacto@seniortalent.cl o hacer click en
      el siguiente chat [insertar link].
    </Typography>
    <Typography variant="body1" paragraph="true">
      Los canales de comunicación que se pongan a disposición de los
      usuarios, como mails, foros y chats (los “
      <Box component="span" fontWeight="fontWeightBold">
        Canales
      </Box>
      ”) deberán ser utilizados de forma responsable, correcta y dando
      fiel cumplimiento a la normativa vigente.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Prohibiciones.
      </Box>
      El usuario reconoce y acepta que las siguientes conductas se
      encuentran prohibidas: (i) utilizar lenguaje vulgar /obsceno,
      discriminatorio y/u ofensivo; (ii) todo tipo de ataque personal
      contra usuarios y/o terceros, mediante acoso, amenazas y/o
      insultos; (iii) todo acto contrario a las leyes, la moral y las
      buenas costumbres; (vi) publicar mensajes, imágenes e
      hipervínculos agraviantes, difamatorios, calumniosos, injuriosos,
      falsos, discriminatorios, pornográficos, de contenido violento,
      insultantes, amenazantes, incitantes a conductas ilícitas o
      peligrosas para la salud, y/o que vulneren de cualquier forma la
      privacidad de cualquier tercero como así también la violación
      directa o indirecta de los derechos de propiedad intelectual de
      Senior Talent y/o de cualquier tercero, incluyendo clientes de
      Senior Talent; (v) publicar mensajes que puedan herir y/o afectar
      la sensibilidad del resto de los usuarios y/o de cualquier
      tercero, incluyendo clientes de Senior Talent; (vi) promocionar,
      comercializar, vender, publicar y/u ofrecer cualquier clase de
      productos, servicios y/o actividades por intermedio de o a través
      de la utilización de los Canales, excepto aquellas expresamente
      permitidas por Senior Talent; (vii) la venta, locación o cesión,
      ya sea a título oneroso o gratuito, del espacio de comunicación de
      los Canales; (viii) publicar mensajes que de cualquier forma
      contengan publicidad; (ix) el uso o envío de virus informáticos,
      malware, spyware, ransomware y/o la realización de todo acto que
      cause o pudiera causar daños o perjuicios al normal funcionamiento
      de los Servicios y/o los Canales, o de los equipos informáticos o
      software de Senior Talent y/o de cualquier tercero, incluyendo
      clientes de Senior Talent; (x) todo acto dirigido a enmascarar y/o
      falsificar o disimular direcciones IP, correos electrónicos y/o
      cualquier otro medio técnico de identificación de los usuarios o
      sus equipos informáticos; (xi) todo acto que viole la privacidad
      de otros usuarios, o que viole cualquiera de sus derechos bajo la
      Ley N° 19.628; (xii) la publicación de datos personales sin el
      consentimiento expreso del titular de los mismos; (xiii) la
      transmisión o divulgación de material que viole la legislación en
      vigor en el país y/o que pudiera herir la sensibilidad del resto
      de los Usuarios y/o de cualquier tercero, incluyendo clientes de
      Senior Talent; (xiv) la publicación de cualquier tipo de contenido
      en violación de derechos de terceros, incluyendo sin limitación
      los derechos de propiedad intelectual y/o industrial.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent no garantiza la veracidad de los datos personales
      y/o contenidos de cada mensaje efectuados y/o publicados en los
      Canales por el usuario. Senior Talent no es responsable de la
      destrucción, alteración o eliminación del contenido o información
      que cada usuario incluya en sus mensajes.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva el derecho de no publicar o remover luego
      de ser publicados todos aquellos contenidos y/o mensajes
      propuestos y/o publicados por el usuario que no respondan a las
      disposiciones contenidas en estos Términos y Condiciones y/o
      resulten impropios y/o inadecuados a las características,
      finalidad y/o calidad de los Servicios.
    </Typography>
    <Typography variant="body1" paragraph="true"></Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        7. REGISTRO. CONTRASEÑAS. DATOS PERSONALES.
      </Box>
    </Typography>

    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Registro.
      </Box>
      Para tener acceso a ciertas funcionalidades del Sitio, se le
      solicitará al usuario que realice un registro específico. En estos
      casos, el usuario deberá completar su información personal en los
      campos correspondientes. La información personal incluye, a modo
      de ejemplo, entre otros, nombre completo, documento de identidad,
      fecha de nacimiento e información de contacto (número de teléfono,
      domicilio y dirección de correo electrónico), antecedentes
      académicos y laborales, entre otros.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Veracidad de los datos.
      </Box>
      Los usuarios registrados y/o que utilicen los Servicios de Senior
      Talent garantizan la veracidad, exactitud, vigencia y autenticidad
      de los datos e información facilitada, y se comprometen a
      mantenerlos debidamente actualizados, informando a Senior Talent
      sobre cualquier modificación a través de la sección “Perfil”
      disponible en la página principal del Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Contraseña.
      </Box>
      El usuario debe ingresar un correo electrónico y crear una
      contraseña personal e intransferible para iniciar sesión en el
      Sitio. El nombre de usuario y la contraseña son confidenciales y
      el usuario debe tomar las precauciones y medidas necesarias para
      evitar el uso indebido por parte de terceros.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario es el único responsable del acceso y las actividades
      realizadas en el Sitio, y debe informar de inmediato a Senior
      Talent en caso de uso indebido o acceso y uso no autorizado por
      parte de terceros. En caso de pérdida o robo de la contraseña de
      acceso, el usuario debe realizar de inmediato el cambie de su
      contraseña en el Sitio. Si por algún motivo dicho cambio no es
      posible, el usuario debe comunicarse con Senior Talent, sujeto a
      seguir siendo responsable de todas las acciones en el Sitio
      realizadas a través del registro de dicho usuario.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Datos Personales.
      </Box>
      En cumplimiento de la Ley 19.628 sobre Protección de Datos de
      Carácter Personal, y con el fin de proteger la seguridad de los
      datos de carácter personal de los usuarios del Sitio, los datos
      que el usuario proporcione a Senior Talent a través de nuestro
      Sitio se encuentran sujetos a nuestras Políticas de Privacidad,
      disponible en [insertar link].
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario autoriza a Senior Talent a almacenar y usar su
      información para los fines previstos en estos Términos y
      Condiciones, a fin de responder a los requerimientos del usuario
      al ingresar al Sitio. El usuario autoriza a que la información
      personal suministrada sea utilizada por Senior Talent, Partes
      Relacionadas, Filiales y/o Prestadores de Servicios.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent excluye toda responsabilidad por los daños y
      perjuicios de toda naturaleza que pudieran deberse al accionar de
      terceros no autorizados respecto de los Datos Personales de los
      usuarios, así como de los Servicios ofrecidos en el Sitio Web.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva el derecho de ofrecerle servicios y
      productos de terceros basados en las preferencias que usuario
      indicó al momento de registrarse o en cualquier momento posterior;
      tales ofertas pueden ser efectuadas por Senior Talent o por
      terceros.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        8. PROPIEDAD INTELECTUAL E INDUSTRIAL.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario acepta y entiende que el Sitio es de propiedad de
      Senior Talent, y/o de cualquier otra sociedad vinculada y/o de sus
      proveedores, incluyendo todos los derechos de propiedad
      intelectual e industrial que se deriven de o se incluyan en el
      mismo, sin que el usuario tenga ningún derecho a utilizarlo, salvo
      en los términos establecidos en las presentes Términos y
      Condiciones.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario no podrá copiar, modificar o reutilizar el Sitio, sus
      actualizaciones o parte de las mismas, incluyendo el software que
      incorporen. El usuario podrá hacer uso del Sitio con propósitos
      lícitos y de acuerdo con los presentes Términos y Condiciones. La
      aceptación de estas condiciones implica la concesión de una
      licencia revocable, intransferible y no exclusiva para ver,
      imprimir y distribuir el contenido del Sitio con fines personales
      y no comerciales siempre y cuando no elimine u oculte el aviso de
      derecho de autor o copyright o cualquier otra nota que figure en
      el contenido del Sitio. No podrá copiar, reimprimir, modificar,
      exhibir, desarrollar, traducir, distribuir, adaptar, transmitir,
      comunicar al público en general a través de medios de
      telecomunicación, divulgar o vender el contenido procedente del
      Sitio de ninguna manera, para ningún uso comercial o divulgarla a
      terceros que tengan fines comerciales, incluyendo otras páginas
      web, sin el consentimiento previo y por escrito de Senior Talent.
    </Typography>
    <Typography variant="body1" paragraph="true">
      En caso de que el usuario descargara un software u otro contenido
      digital, incluyendo aplicaciones desde el Sitio (“{' '}
      <Box component="span" fontWeight="fontWeightBold">
        Productos
      </Box>
      ”), Senior Talent le otorgará la licencia de los Productos y todos
      los archivos, imágenes y datos relacionados con los términos y
      condiciones aplicables. El usuario acepta y reconoce no ser el
      propietario de los Productos descargados, y que Senior Talent no
      le transfiere la propiedad de los Productos. Senior Talent
      mantiene la propiedad absoluta y el título de los Productos
      descargados y todos los derechos de propiedad intelectual
      relacionados con los mismos. No puede redistribuir, vender,
      descompilar, aplicar ingeniería inversa, desmontar o reducir los
      Productos a una forma legible para el ser humano.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Los contenidos de este Sitio están protegidos por las leyes
      aplicables, incluyendo, pero sin limitación, las leyes sobre
      derechos de autor, patentes, marcas, modelos de utilidad, diseños
      industriales y nombres de dominio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario reconoce el derecho, título e interés de Senior Talent
      en las marcas registradas o no, insignias, logotipos, diseños,
      palabras o nombres no registrados que identifican y distinguen a
      Senior Talent y acepta no participar en actividades o cometer acto
      alguno que, directa o indirectamente, pueda disputar o poner en
      riesgo dicho derecho, título o interés de Senior Talent. Asimismo,
      el Solicitante no deberá solicitar ni adquirir ante las
      autoridades competentes, bien como marca de producto, servicio,
      denominación, nombre de dominio, razón social o nombre comercial;
      marcas iguales, parecidas o confundibles a las marcas registradas
      u otros signos no registrados que identifiquen y distingan a
      Senior Talent, ni reclamar derechos, títulos o intereses en o
      sobre las marcas de Senior Talent.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El uso, adaptación, reproducción y/o comercialización no
      autorizada del contenido del Sitio puede encontrarse penado por la
      legislación vigente.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        9. MENORES DE EDAD.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Al acceder al Sitio y utilizar los Servicios el usuario confirma
      que es mayor de edad.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Queda prohibida la utilización del Sitio y/o de los Servicios
      ofrecidos a través del mismo por personas que carezcan de
      capacidad legal para contratar o menores de edad según la
      legislación aplicable conforme la cláusula18. En caso que ello
      ocurra, los menores de edad o incapaces deben obtener previamente
      permiso de sus padres, tutores o representantes legales, quienes
      serán considerados responsables de todos los actos realizados por
      las personas a su cargo.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        10. RESPONSABILIDAD DEL USUARIO. CESIÓN.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario declara y acepta que el uso del Sitio, sus Servicios y
      los contenidos se efectúan bajo su única y exclusiva
      responsabilidad.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent no será responsable de ningún tipo de daño o
      perjuicio que el usuario sufra en relación a cualquier uso que
      realice del Sitio con fines comerciales u otros propósitos no
      autorizados bajo estos Términos y Condiciones.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario entiende y acepta que la utilización de cualquier
      material y/o datos descargados u obtenido a través del Sitio se
      realiza bajo su propia responsabilidad y será el único responsable
      de cualquier daño a su sistema informático o pérdida de datos en
      su ordenador que resulte de la descarga de dicho material y/o
      datos.
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario acepta no ceder, bajo ningún título, sus derechos u
      obligaciones bajo estas Condiciones y/o las condiciones
      particulares que pudieran aplicar a los Servicios.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        11. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD. MANTENIMIENTO.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Exclusión de Garantías.
      </Box>{' '}
      El Sitio y los Servicios se suministran tal como están, sin
      garantías de ninguna clase. Senior Talent no garantiza (i) que
      cada usuario es quien dice ser; (ii) la veracidad de los datos
      personales; (iii) que el Sitio funcione libre de errores o que el
      Sitio y su servidor estén libres de virus informáticos u otros
      mecanismos lesivos; (iv) la exactitud, la veracidad, la
      exhaustividad o la actualización de los contenidos, los Servicios,
      el software, los textos, los gráficos y los vínculos; (v) que el
      acceso al Sitio sea ininterrumpido, puntual, seguro o libre de
      errores, o que los defectos, en caso de haberlos, vayan a ser
      subsanados; (vi) los resultados que se puedan obtener del uso del
      Sitio o en cuanto a la exactitud, calidad o fiabilidad de la
      información obtenida a través del mismo; (vii) la disponibilidad y
      continuidad del funcionamiento de los Canales de Comunicación.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Responsabilidad.
      </Box>{' '}
      De conformidad a la legislación vigente, bajo ninguna
      circunstancia Senior Talent, sus Partes Relacionadas, Filiales y/o
      Prestadores de Servicios, sus licenciantes o licenciatarios serán
      responsables ante el usuario o cualquier otra persona o entidad
      por daños directos, indirectos, especiales, incidentales,
      consecuentes, punitivos o de otro tipo, incluidas las perdidas,
      lesiones personales (incluida la muerte) y daños a la propiedad,
      que puedan resultar de (i) el uso o la imposibilidad de usar el
      Sitio o cualquier material publicado en el Sitio; (ii) la conducta
      de cualquier otro usuario o terceros en el Sitio; (iii) el acceso
      no autorizado o la modificación de datos personales del usuario; y
      (iv) cualquier otra cuestión relativa al Sitio. Senior Talent no
      será responsable, incluso si Senior Talent o un representante
      autorizado de esta ha sido informado de la posibilidad de dichos
      daños. Estos incluyen, pero no están limitados a, daños o lesiones
      causadas por error, omisión, interrupción, defecto, falla de
      funcionamiento, retraso en la operación o transmisión, falla de
      línea o virus de computadora, gusano, caballo de Troya u otro
      componente dañino.
    </Typography>
    <Typography variant="body1" paragraph="true">
      En el evento que la legislación vigente no permita la exclusión de
      ciertas garantías o la limitación o exclusión total de
      responsabilidad en cuyo caso la limitación de responsabilidad
      antedicha será interpretada en la máxima extensión que permita la
      ley.
    </Typography>
    <Typography variant="body1" paragraph="true">
      <Box component="span" fontWeight="fontWeightBold">
        Mantenimiento.
      </Box>{' '}
      El material en los Sitios podría incluir inexactitudes técnicas o
      errores tipográficos y podría ser inexacto o volverse inexacto
      como resultado de desarrollos posteriores a sus respectivas
      fechas. Senior Talent no asume la obligación de verificar o
      mantener la vigencia de dicha información.
    </Typography>
    <Typography variant="body1" paragraph="true">
      La puesta a disposición del Sitio es gratuita y, sobre esta base,
      Senior Talent no tiene ninguna obligación de mantenimiento o de
      servicio de soporte y no se hace responsable de cualquier
      perjuicio o daño que el usuario pueda sufrir como consecuencia de
      un fallo derivado del mantenimiento o actualización del Sitio .
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        12. VÍNCULOS A OTROS SITIOS.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      El Sitio contiene vínculos a otros sitios de Internet. Senior
      Talent no respalda los contenidos de estos sitios web. Senior
      Talent no es responsable del contenido del Sitio web de terceros y
      no hace ninguna afirmación relativa al contenido o su exactitud en
      estos sitios web de terceros. Si el usuario decide acceder a
      sitios web de terceras partes vinculados, lo hace a su propio
      riesgo.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        13. CANCELACIÓN.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva el derecho de suspender o cancelar (dar
      de baja) a cualquier usuario que no cumpla con los estándares
      definidos en estos Términos y Condiciones o con las políticas
      vigentes de Senior Talent, sin que ello genere derecho a
      resarcimiento alguno.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Sin perjuicio de lo indicado, Senio Talent se reserva el derecho,
      a su exclusivo criterio, de emplear todos los medios legales a su
      alcance en caso que usuario infrinja cualquiera de estos Términos
      y Condiciones.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        14. INDEMNIZACIÓN
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      El usuario se compromete a indemnizar y eximir a Senior Talent,
      sus accionistas, su compañía matriz, sus subsidiarias o afiliadas,
      controladas y controlantes, sus directores, funcionarios,
      empleados y agentes, prestadores de servicios, licenciatarios,
      sucesores y cesionarios, por todos los daños y perjuicios que
      pudieran originarse de cualquier reclamo, acción o demanda,
      incluyendo el pago de los costos y costas, honorarios de abogados,
      gastos de cualquier índole, multas o sanciones originados por la
      violación a cualquier obligación asumida por el usuario, de
      conformidad con los términos, condiciones y garantías estipulados
      en estos Términos y Condiciones, así como también en las
      condiciones particulares que pudieran aplicar a los Servicios.
      Esta obligación de indemnidad resulta extensiva a cualquier
      reclamo, daño y/o perjuicio que pudiera derivar de las relaciones
      existentes entre los Solicitantes y los usuarios del Sitio Web
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        15. MODIFICACIÓN DE TÉRMINOS Y CONDICIONES.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Senior Talent se reserva el derecho de efectuar, esporádicamente,
      cambios en los Términos y Condiciones a su entera discreción
      mediante su actualización en el Sitio, y la indicación de la fecha
      de aplicación de la nueva versión de las mismas. La fecha de
      “Última Actualización” incluida al final de este aviso legal
      indicará cuándo se realizaron los cambios. Que el Usuario continúe
      usando los Sitios tras la publicación de una nueva versión de los
      Términos y Condiciones implicará su aceptación de tales cambios.
    </Typography>
    <Typography variant="body1" paragraph="true">
      En caso de declararse la nulidad de alguna de las cláusulas de
      estos Términos y Condiciones, tal nulidad no afectará a la validez
      de las restantes, las cuales mantendrán su plena vigencia y
      efecto.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        16. AUSENCIA DE RENUNCIA.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Ninguna imprecisión por parte de Senior Talent en el
      incumplimiento de estos Términos y Condiciones constituirá una
      renuncia a ninguno de los derechos de Senior Talent bajo estos
      Términos y Condiciones ya sea por acciones pasadas o futuras por
      parte de cualquier persona. Solamente una renuncia por escrito y
      firmada por un representante legal de Senior Talent tendrá efecto
      legal.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        17. DURACIÓN Y TERMINACIÓN.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      La duración de la prestación del servicio del Sitio y de los
      Servicios es de carácter indefinido. Sin perjuicio de lo anterior,
      Senior Talent se reserva el derecho de interrumpir, suspender o
      terminar la prestación del servicio del Sitio o de cualquiera de
      los Servicios que lo integran, sin expresión de causa.
    </Typography>
    <Typography variant="subtitle1">
      <Box component="span" fontWeight="fontWeightBold">
        18. LEY APLICABLE Y COMPETENCIA.
      </Box>
    </Typography>
    <Typography variant="body1" paragraph="true">
      Los presentes Términos y Condiciones, así como la relación entre
      el usuario y Senior Talent, se regirán e interpretarán con arreglo
      a la legislación vigente de la República de Chile. Para todos los
      efectos legales, el usuario y Senior Talent fijan su domicilio en
      la comuna y ciudad de Santiago de Chile, y prorrogan competencia a
      sus tribunales ordinarios de justicia.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Estos Términos y Condiciones, junto con la Política de Privacidad
      constituyen la totalidad del acuerdo entre usuario y Senior Talent
      respecto del uso del Sitio.
    </Typography>
    <Typography variant="body1" paragraph="true">
      Estos Términos y Condiciones fueron actualizados por última vez el
      [________] de 2020.
    </Typography>
  </Box>
  );
}

