import React, { useState } from 'react';
import Structure from '../structure';
import {Grid, Typography, TextField, InputAdornment, Button, TextareaAutosize, FormHelperText} from '@material-ui/core';
import axios from "axios";
import {FONT_FAMILY, THEME_COLOR} from '../../helpers/constants';

const SERVICE_MAIL_URL = process.env.REACT_APP_SERVICE_EMAIL || "dummy";
const schemaValidation = {
    fullname: (fullname) => {
        // console.log("fullname length: " + fullname.length);
        if(fullname.trim().length === 0) {
            return false;
        }
    
        const validFullNameRegex = RegExp(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/);
        return validFullNameRegex.test(fullname);
    },
    email: (email) => {
        const validEmailRegex = RegExp(/\S+@\S+\.\S+/);
        return (validEmailRegex.test(email) && email.trim().length > 0);
    },
    phone: (cellphone) => {
        const validCellPhoneRegex = RegExp(/^(\s?)[9876543]\d{7}$/);
        return (validCellPhoneRegex.test(cellphone) && cellphone.trim().length > 0);
    },
    message: (message) => {
        return message.length > 5 && message.length < 501
    }
}

const initialFieldValues = {
    fullname: '',
    email: '',
    phone: '',
    message: ''
}

const initialErrorsValue = {
    fullname: false,
    email: false,
    phone: false,
    message: false
}

const Contact = () => {
    const classes = Structure();

    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState(initialErrorsValue);

    /**
     * 
     * @param {*} e 
     */
    const handleInputChange = e => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        });

        setErrors({
          ...errors,
          [name]: !schemaValidation[name](value)
        })
    }

        /**
     * 
     * @param {*} data 
     */
    const validateForm = () => {
        let result = true;
        let newErrorState = {};

        Object.keys(initialFieldValues).forEach((field) => {
            let checkField = !schemaValidation[field](values[field]);
            newErrorState[field] = checkField;

            if (checkField) {
                result = !checkField;
            }
        });

        setErrors(newErrorState);

        return result;
    }

    /**
     * 
     */
     const sendFormHandler = () => {

        if(!validateForm()) {
            alert("Formulario con errores, por favor revise los valores ingresados.");
            return false;
        }
        // console.log("sending contact form...");
        
        axios({
            method: 'post',
            url: SERVICE_MAIL_URL,
            data: {...values, name: values.fullname},
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(resp => {
            if (resp.status === 200) {
              alert(`Hemos recibido tu mensaje, te contactaremos a la brevedad!`);
            }
      
            setValues(initialFieldValues);
          })
          .catch(err => {
            alert("Tenemos problemas con el servicio, por favor reintente más tarde.");
            // console.log(err);
          });

        return true;
    }

    return (
            <Grid container spacing={1} style={{ backgroundColor: "#FFF", paddingTop: "40px", paddingBottom: "40px"}}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center'}}>
                        <Typography variant="h6" style={{ fontFamily:FONT_FAMILY, fontSize: "48px", color: "#3155A4"}}>
                            CONTACTANOS!
                        </Typography>
                        <Typography variant="subtitle1" style={{fontFamily:FONT_FAMILY, fontSize: "18px", paddingBottom: "40px"}}>
                        Si tienes dudas, o te gustaría participar de esta comunidad.
                        </Typography>
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                            <Grid container spacing={1}>
                                    <TextField 
                                        fullWidth={true}
                                        name="fullname" 
                                        variant="outlined" 
                                        label="Nombre completo" 
                                        value={values.fullname} 
                                        onChange={handleInputChange}
                                        size="small"
                                        error={errors.fullname}
                                        helperText={ errors.fullname ? "Por favor ingrese su nombre completo." : ""}
                                    />
                            </Grid>
                            <Grid container spacing={1}>
                                
                                <Grid item xs={6} style={{marginTop: "20px"}}>
                                    <TextField 
                                        fullWidth={true}
                                        name="email" 
                                        variant="outlined" 
                                        label="Correo Electrónico" 
                                        value={values.email} 
                                        onChange={handleInputChange}
                                        size="small"
                                        error={errors.email}
                                        helperText={ errors.email ? "Por favor ingrese un correo válido. Ej.: profesional@seniortalent.cl" : ""}
                                    />
                                </Grid>
                                <Grid container item xs={6} spacing={1} style={{marginTop: "20px"}}>
                                    <TextField 
                                        fullWidth={true}
                                        name="phone"
                                        variant="outlined" 
                                        label="Teléfono Móvil" 
                                        value={values.phone} 
                                        onChange={handleInputChange}
                                        size="small"
                                        error={errors.phone}
                                        helperText={ errors.phone ? "Por favor ingrese un teléfono de contacto." : ""}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+56 9</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: "20px"}}>
                                <TextareaAutosize style={{ fontFamily:FONT_FAMILY, width: '99%'}} 
                                    name="message"
                                    rowsMin={10}
                                    aria-label="maximum height"
                                    placeholder="Mensaje"
                                    defaultValue=""
                                    value={values.message}
                                    onChange={handleInputChange}
                                />
                                { errors.message ? 
                                <FormHelperText><label style={{ color: "red"}}>El mensaje debe tener entre 5 y 500 caracteres, por favor revise los valores ingresados.</label></FormHelperText>
                                :
                                "" }
                            </Grid>
                            <Grid item xs={12}>
                        <Button 
                            color="secondary"
                            style={{ fontFamily: FONT_FAMILY, backgroundColor: THEME_COLOR.AcentColor, borderRadius: "12px",fontWeight:'bold',fontSize:'18px'}}
                            variant="contained" 
                            fullWidth={true}
                            onClick={sendFormHandler}
                        >
                            Enviar Mensaje
                        </Button>
                </Grid>
                        </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
    );

}

export default Contact;