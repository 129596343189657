import React, { useState, useEffect } from 'react';
import { List, Input,OutlinedInput, ListItem, ListItemIcon, ListItemText, TextField, Box, InputLabel, Grid, Select, FormControl, MenuItem, FormHelperText, Button, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import Add from '@material-ui/icons/Add';
import Upload from '../upload';
import BasicTable from '../table';
import { v4 as uuidv4 } from 'uuid';
import documentTypesValues from '../../datasource/documents.json';
import { checkType, checkFile } from '../../helpers/validate';
import { FONT_FAMILY } from '../../helpers/constants';

const defaultBoxProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
  style: { width: 'auto', height: 'auto' },
};

const tableHeadData = [
  { key: "type", value: "Tipo" },
  { key: "name", value: "Nombre Archivo" }
]

const initialCurrentDocument = {
  type: '',
  name: '',
  file: {},
  filelocation: ''
}

const schemaValidation = {
  type: checkType,
  name: (n) => n.trim().length > 0,
  file: checkFile,
  filelocation: (l) => l !== ''
}

const style = {
  labelForm: {
    fontFamily: FONT_FAMILY,
    color: '#1A202C',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  subLabelForm: {
    fontFamily: FONT_FAMILY,
    color: '#000000',
    fontSize: '12px'
  },
  inputForm: {
    fontFamily: FONT_FAMILY,
    color: '#CBD5E0'
  },
  buttonSubmit: {
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  },
  buttonSubmit2: {
    display:'none',
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  }
}

const Documents = (props) => {
  const {
    value,
    setValues,
    setError
  } = props;

  // const [myDocumentMap, setMyDocumentMap] = useState(value);
  const [doc, setDoc] = useState(initialCurrentDocument);
  const [newError, setNewError] = useState(false);

  const inputRef = React.useRef(null)

  const updateDocumentValues = () => {
    setValues(new Map(value));
    setError(new Map(value));
  }

  // useEffect(() => {
  //    updateDocumentValues();
  //   // // console.log("useEffect Documents!")
  // },[value]);

  useEffect(() => {
    setNewError(false);
  }, [doc]);

  /**
   *
   * @returns
   */
  const validateDocument = () => {
    let result = true;
    ['name', 'type', 'file', 'filelocation'].forEach((field) => {
      let checkField = !schemaValidation[field](doc[field]);
      console.log(`Field ${field} value ${doc[field]} => error? ${checkField}`)

      if (checkField) {
        result = !checkField;

        setNewError(true);
      }
    });

    return result;
  }

  /**
   * Handle add new document action
   */
  const handleAddDocumentClicked = () => {
    if (!validateDocument()) {
      alert("El documento tiene errores, por favor revise los valores ingresados.");
      return false;
    }

    // Update state with document
    setValues(new Map(value.set(uuidv4(), doc)));

    updateDocumentValues();

    // Clean add new document form
    setDoc({
      type: '',
      name: '',
      file: {},
      filelocation: ''
    });
  }

  /**
   * Handler of internal reference state
   * @param {*} e
   */
  const handleDocumentInputChange = e => {
    const { name, value } = e.target;
    setDoc({
      ...doc,
      [name]: value
    })
  }

  const es6Function = (value) => {
    console.log(value)
    inputRef.current.click()
  }



  /**
  * Get the value depending of the type
  *
  * @param {*} tableCellValue
  */
  const processCell = (propName, tableCellKey, tableCellValue) => {
    let responseValue = "";

    switch (propName) {
      case "name":
        responseValue = <List dense={true} key={uuidv4()}>
          <ListItem button key={uuidv4()} onClick={() => { window.open(tableCellValue["filelocation"], "_blank") }}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={tableCellValue["name"]}
            // secondary={tableCellValue["type"]}
            />
          </ListItem>
        </List>
        break;
      default:
        responseValue = tableCellValue[propName];
        break;
    }
    return responseValue;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {newError ?
          <FormHelperText style={{ color: "red" }}>El documento tiene errores, por favor revise los valores ingresados.</FormHelperText>
          :
          ""}
        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Tipo
                  </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined">
                <Select
                  value={doc ? doc.type : ''}
                  name="type"
                  variant="outlined"
                  onChange={handleDocumentInputChange}
                  input={<OutlinedInput />}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {documentTypesValues.map((docType) => (
                    <MenuItem key={documentTypesValues.indexOf(docType)} value={docType}>
                      {docType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Nombre
                  </Typography>
              <Typography style={style.subLabelForm} variant="subtitle1">Cualquier documentos que creas relevante que manejemos para respaldar experiencia</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="name"
                variant="outlined"
                label=""
                placeholder='Curriculum/ Certificado de Titulo / carpeta proyectos etc'
                value={doc.name}
                onChange={handleDocumentInputChange}
                size="small"
              />
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <Grid
            m={4}
            container
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography style={style.labelForm} component='h5'>
                Archivo
                  </Typography>
            </Grid>
            <Grid item xs={8}>
              <Upload
                setFileLocation={(filelocation) => setDoc({ ...doc, filelocation })}
                value={doc.file}
                setValues={(file) => setDoc({ ...doc, file })}
                es6Function = {es6Function}
              />
            </Grid>
          </Grid>
        </Box>

      </Grid>

      <Grid container justify='flex-end'>
        <Box m={3}>
          <Button  ref={inputRef} style={style.buttonSubmit2} variant="contained" aria-label="add document" onClick={handleAddDocumentClicked}>
            Agregar <Add></Add>
          </Button>

        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box m={3}>
          <BasicTable
            headData={tableHeadData}
            tableData={value}
            tableCellProcess={processCell}
            setTableData={setValues}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Documents;
