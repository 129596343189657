import React from 'react';
import Structure from '../structure';
import {Grid, Typography} from '@material-ui/core';
import protagonisImage from '../../images/protagonist.svg';
import { FONT_FAMILY } from '../../helpers/constants';

const Protagonist = () => {

    const classes = Structure();

    return (
            <Grid container spacing={1} style={{ backgroundColor: '#3155A4', backgroundImage: `url(${protagonisImage})`, paddingTop: "40px", paddingBottom: "20px"}}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center'}}>
                        <Typography variant="h4" style={{ fontFamily: FONT_FAMILY, fontSize: "34px", fontWeight: "bold", color: "#FFF", marginBottom: "20px"}}>
                            EL PROTAGONISTA ERES TÚ
                        </Typography>
                        <Typography variant="subtitle1" style={{fontFamily:FONT_FAMILY, fontSize: "16px", color: "#FFF", marginTop: "30px", marginBottom: "40px"}}>
                            Te invitamos a conectar con tus pares que cumplan con el perfil de la comunidad. El objetivo <br />
                            es convocar a un segmento de la población activa laboralmente con menos visibilidad, a una <br/>
                            plataforma de encuentro y colaboración para seguir activos, compartir experiencias y <br />
                            oportunidades.<span style={{fontSize:'18px',fontWeight:'bold'}}>Bienvenidos todos!!</span>.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
    );

}

export default Protagonist;