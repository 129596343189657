import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import {
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
  Button
} from '@material-ui/core';
import introImage from '../../images/intro2.png';
import { ProfessionalForm } from 'lib-professional-form';
import { CompanyForm } from 'lib-company-form';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';
import ReactPlayer from 'react-player';

const Intro = () => {
  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState('');

  /**
   * Based in selected element it will show/hide edit dialog.
   */
  useEffect(() => {
    setOpenForm(formType.length > 0);
  }, [formType]);

  const handleShowForm = (e) => {
    const { currentTarget } = e;
    const formType = currentTarget.getAttribute('data');

    setFormType(formType);

    // console.log(`Abrir form of type ${formType} `);
  };

  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size 
const handleResize = () => {
  if (window.innerWidth < 600) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
}

// create an event listener
useEffect(() => {
  window.addEventListener("resize", handleResize)
})

const styleMobile={
  subtitle1:{
    fontSize: '20px',
    letterSpacing: '0.25px',
    marginBottom: '2em',
    fontFamily:FONT_FAMILY,
    color: THEME_COLOR.PrimaryDark
  }
}
const style={
 
  subtitle1:{
    fontSize: '34px',
    letterSpacing: '0.25px',
    marginBottom: '2em',
    fontFamily:FONT_FAMILY,
    color: THEME_COLOR.PrimaryDark
  }
}

  return (
    <Grid
      container
      spacing={1}
      style={{
        backgroundImage: `url(${introImage})`,
        height: '100%',
        width:'100%',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setFormType('')}
        aria-labelledby="form-dialog-title"
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ textAlign: 'center' }}>
            <InputLabel
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: '34px',
                color: '#3155A4',
              }}
            >
              Registro para{' '}
              {formType == 'Profesional' ? 'profesionales' : 'empresas'}
            </InputLabel>
          </div>
        </DialogTitle>
        {formType == 'Profesional' ? (
          <ProfessionalForm
            title=""
            handlerCloseForm={() => setOpenForm(false)}
            message={true}
          />
        ) : (
          <CompanyForm title="" handlerCloseForm={() => setOpenForm(false)} />
        )}
      </Dialog>
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography
          variant="h6"
          style={{
            fontSize: '48px',
            fontWeight: 'bold',
            color: '#3155A4',
            marginTop: '60px',
            fontFamily: FONT_FAMILY
          }}
        >
          Senior Talent
        </Typography>
        <ReactPlayer
          width="30em"
          height="20em"
          playsinline={true}
          url="https://player.vimeo.com/video/528584448"
        />
        <Typography
          variant="subtitle1"
          style={isMobile ? styleMobile.subtitle1 : style.subtitle1}
        >
          El valor de la experience al alcance de tu negocio
        </Typography>

        <Typography
          variant="subtitle1"
          style={{
            fontSize: '20px',
            letterSpacing: '0.25px',
            marginBottom: '1em',
            fontFamily:FONT_FAMILY,
            color: THEME_COLOR.PrimaryDark
          }}
        >
          ¡Únete a nuestra comunidad!
        </Typography>

        <div style={{ height: '48px' }}>
          <Button
          variant="contained" 
            style={{
              color: 'white',
              backgroundColor: '#FD513B',
              height: '48px',
              width: '197px',
              borderRadius: '40px',
              padding: '10px',
              margin: '0px 10px 0px 10px',
            }}
            data="Profesional"
            onClick={handleShowForm}
          >
            SOY PROFESIONAL
          </Button>
          <Button
          variant="contained" 
            style={{
              color: 'white',
              backgroundColor: '#3155A4',
              height: '48px',
              width: '197px',
              borderRadius: '40px',
              padding: '10px',
              margin: '0px 10px 0px 10px',
            }}
            data="Empresa"
            onClick={handleShowForm}
          >
            SOY EMPRESA
          </Button>
        </div>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: '24px',
            textAlign: 'center',
            margin: '40px 80px 40px 80px',
            fontFamily:FONT_FAMILY,
            color: THEME_COLOR.GreyDarkest
          }}
        >
          Somos el puente que vincula a la comunidad de Profesionales sobre 50
          años con las empresas que necesiten de su experience, en un formato
          flexible y ajustado a sus necesidades.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Intro;
