import React from 'react';
import Structure from '../structure';
import {Grid, Typography} from '@material-ui/core';
import protagonisImage from '../../images/protagonist.svg';
import { FONT_FAMILY } from '../../helpers/constants';

const Vision = () => {

    const classes = Structure();

    return (
            <Grid container spacing={1} style={{ backgroundImage: `url(${protagonisImage})`}}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center'}}>
                        <Typography variant="h6" style={{ fontFamily:FONT_FAMILY, fontSize: "30px", fontWeight: "bold", color: "#3155A4"}}>
                            VISION
                        </Typography>
                        <Typography variant="subtitle1" style={{ fontFamily:FONT_FAMILY, fontSize: "18px", margin: "40px 80px 40px 80px"}}>
                            Buscamos convertirnos en la red más grande de Chile para la inserción laboral de
                            profesionales sobre 50 años y a la vez profundizar la profesionalización de las
                            PYMES mediante la utilización de sus servicios.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
    );

}

export default Vision;