import React from 'react';
import Structure from '../structure';
import { Grid, Typography, Box } from '@material-ui/core';
import { FONT_FAMILY } from '../../helpers/constants';

const Benefit = () => {
  const classes = Structure();

  return (
    <Grid
      container
      spacing={0}
      style={{ paddingTop: '40px', paddingBottom: '40px' }}
    >
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h6"
            style={{ fontFamily: FONT_FAMILY,fontSize: '48px', color: '#3155A4' }}
          >Nuestro Compromiso</Typography>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: FONT_FAMILY,
              fontSize: '18px',
              fontWeight:'bold',
              letterSpacing: '0.5px',
              lineHeight: '28px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            Es generar impacto en la sociedad
          </Typography>
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div
            style={{
              flexGrow: 1,
              height: '250px',
              backgroundColor: '#FFF',
              borderRadius: '20px',
            }}
          >
            <Box pt={3}>
              <Box>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    letterSpacing: '0.15px',
                    color: '#0A1746',
                    marginLeft: '20px',
                  }}
                >
                  Profesional
                </Typography>
              </Box>
              <Box p={1}>
                <ul style={{ color: 'blue' }}>
                  <li>
                    <span style={{ fontFamily: FONT_FAMILY, color: 'black' }}>
                      Aumentar la empleabilidad de profesionales sobre 50 años
                    </span>
                  </li>
                  <li>
                    <span style={{ fontFamily: FONT_FAMILY, color: 'black' }}>
                      Mantenerse vigente al pertenecer a una comunidad que se
                      retroalimenta de experiencia y conocimiento.
                    </span>
                  </li>
                </ul>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            style={{
              flexGrow: 1,
              height: '250px',
              backgroundColor: '#FFF',
              borderRadius: '20px',
            }}
          >
            <Box pt={3}>
              <Box>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    letterSpacing: '0.15px',
                    color: '#0A1746',
                    marginLeft: '20px',
                  }}
                >
                  Empresa
                </Typography>
              </Box>
              <Box p={1}>
                <ul style={{ color: 'blue' }}>
                  <li>
                    <span style={{ fontFamily: FONT_FAMILY, color: 'black' }}>
                      Una fuerza laboral heterogenea en edad, entrega una mayor
                      variedad cognitiva, experiencia, mejorando la creatividad,
                      innovacion y los resultados de la empresa.
                    </span>
                  </li>
                </ul>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            style={{
              flexGrow: 1,
              height: '250px',
              backgroundColor: '#FFF',
              borderRadius: '20px',
            }}
          >
            <Box pt={3}>
              <Box>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: FONT_FAMILY,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    letterSpacing: '0.15px',
                    color: '#0A1746',
                    marginLeft: '20px',
                  }}
                >
                  Compromiso Social
                </Typography>
              </Box>
              <Box p={1}>
                <ul style={{ color: 'blue' }}>
                  <li>
                    <span style={{ fontFamily: FONT_FAMILY, color: 'black' }}>
                      Nos inspira ayudar a transformar el mercado laboral a
                      traves de la integracion de profesionales sobre 50 años,
                      haciendolos visibles para la sociedad, y con ellos
                      aumentar su autoestima, evitar jubilacion anticipada,
                      mejorar calidad de vida y la de su entorno.
                    </span>
                  </li>
                </ul>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Benefit;
