import React, { useState, useEffect } from 'react';
import Structure from '../structure';
import {
  Paper,
  Grid,
  Typography,
  MenuList,
  MenuItem,
  Box,
  Hidden,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
} from '@material-ui/core';
import knowledgeIcon from '../../images/conocimiento.svg';
import disciplineIcon from '../../images/disciplina.svg';
import autonomyIcon from '../../images/autonomia.svg';
import clientsIcon from '../../images/clientes.jpeg';
import { ProfessionalForm } from 'lib-professional-form';
import { CompanyForm } from 'lib-company-form';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';

const Client = () => {
  const classes = Structure();

  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setOpenForm(formType.length > 0);
  }, [formType]);

  const handleShowForm = (e) => {
    const { currentTarget } = e;
    const formType = currentTarget.getAttribute('data');
    setFormType(formType);
  };


  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const styleMobile = {
    content:{ flexGrow: 1, height: '90vh', minHeight: '90vh' },
    button: {
      color: 'white',
      backgroundColor: '#3155A4',
      height: '48px',
      width: '197px',
      borderRadius: '40px',
      padding: '10px',
      margin: '0px 10px 0px 10px',
      marginLeft: '27vw',
    },
  };
  const style = {
    content:{ flexGrow: 1, height: '85vh', minHeight: '85vh' },
    button: {
      color: 'white',
      backgroundColor: '#3155A4',
      height: '48px',
      width: '197px',
      borderRadius: '40px',
      padding: '10px',
      margin: '0px 10px 0px 10px',
      marginLeft: '15vw',
    },
  };


  return (
    <Grid
      container
      spacing={1}
      style={{ backgroundColor: '#FFF', paddingTop: '40px' }}
    >
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setFormType('')}
        aria-labelledby="form-dialog-title"
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ textAlign: 'center' }}>
            <InputLabel
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: '34px',
                color: '#3155A4',
              }}
            >
              Registro para{' '}
              {formType == 'Profesional' ? 'profesionales' : 'empresas'}
            </InputLabel>
          </div>
        </DialogTitle>
        {formType == 'Profesional' ? (
          <ProfessionalForm
            title=""
            handlerCloseForm={() => setOpenForm(false)}
            message={true}
          />
        ) : (
          <CompanyForm title="" handlerCloseForm={() => setOpenForm(false)} />
        )}
      </Dialog>
      <Grid item sm={12} md={6}>
        <div style={isMobile ? styleMobile.content : style.content}>
          <Box
            display="flex"
            height="100%"
            style={{ marginLeft: '40px', marginRight: '40px' }}
          >
            <Box>
              <Typography
                variant="h6"
                style={{
                  fontFamily: FONT_FAMILY,
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#3155A4',
                }}
              >
                ¿QUIÉNES SON NUESTROS CLIENTES?
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: FONT_FAMILY,
                  fontSize: '16px',
                  marginTop: '20px',
                  marginRight: '80px',
                  marginBottom: '40px',
                }}
              >
                Cualquier empresa de preferencia PYMES que necesite incorporar
                profesionales con vasta experiencia en sus áreas de expertise
                para dar soluciones de negocio. Son empresas que no han podido
                acceder a este talento por razones de valor o alcance.
              </Typography>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={knowledgeIcon} alt="conocimiento" />
                    </Box>
                    <Box paddingTop={2}>
                      <Typography variant="subtitle2">
                        Accede al talento que tu negocio necesita y en un
                        formato que te acomode
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={autonomyIcon} alt="autonomía" />
                    </Box>
                    <Box paddingTop={1.5}>
                      <Typography variant="subtitle2">
                        Publica tu requerimiento o necesidad y recibirás las
                        ofertas de los perfiles <br /> que calcen con tu
                        necesidad.
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box display="flex" p={0} m={0}>
                    <Box p={1}>
                      <img src={disciplineIcon} alt="disciplina" />
                    </Box>
                    <Box paddingTop={1.5}>
                      <Typography variant="subtitle2">
                        Negocia directamente con el profesional los términos y
                        condiciones que les <br />
                        acomoda a ambos.
                      </Typography>
                    </Box>
                  </Box>
                </li>
              </ul>
              <Button
                variant="contained"
                style={isMobile ? styleMobile.button : style.button}
                data="Empresa"
                onClick={handleShowForm}
              >
                SOY EMPRESA
              </Button>
            </Box>
          </Box>
        </div>
      </Grid>

      <Grid item md={6}>
        <Hidden smDown>
          <div
            style={{
              flexGrow: 1,
              height: '85vh',
              minHeight: '85vh',
              textAlign: 'center',
            }}
          >
            <img
              src={clientsIcon}
              alt="seniorwoman"
              style={{ height: '80vh' }}
            />
          </div>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default Client;
