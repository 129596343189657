import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Drawer,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import seniortalentLogo from '../../images/seniortalent.svg';
import PersonIcon from '@material-ui/icons/Person';
import {FONT_FAMILY, THEME_COLOR} from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: '#FFF',
  },
  title: {
    flexGrow: 1,
    color: 'orange',
  },
  item: {
    fontWeight: 'bold',
    color: THEME_COLOR.NavyDark,
    fontSize: '12px',
    fontFamily:FONT_FAMILY
    
  },
}));

const NavBar = ({ handleScrollToSection }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = React.useState({
    top: false,
  });
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const openAdminPanel = (e) => {
    window.open('https://seniortalent-admin.web.app/login', '_blank');
    handleClose(e);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuWebsiteMobile = [
    {
      title: '¿Qué hacemos?',
      disabled: false,
      data: 'whatwedo-section',
      handler: handleScrollToSection,
    },
    {
      title: '¿Eres un Senior Talent?',
      disabled: false,
      data: 'customer-section',
      handler: handleScrollToSection,
    },
    {
      title: 'Nuestros Clientes',
      disabled: false,
      data: 'client-section',
      handler: handleScrollToSection,
    },
    {
      title: 'Nuestro Compromiso',
      disabled: false,
      data: 'benefit-section',
      handler: handleScrollToSection,
    },
    {
      title: 'Contacto',
      disabled: false,
      data: 'contact-section',
      handler: handleScrollToSection,
    },
    {
      title: 'Acceso',
      disabled: false,
      data: 'panel',
      handler: openAdminPanel,
    },
  ];

  const list = (anchor) => (
    <div
      style={{ width: '100vw' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuWebsiteMobile.map((o, index) => (
          <ListItem
            button
            data={o.data}
            key={o.title}
            disabled={o.disabled}
            onClick={o.handler}
          >
            <ListItemText primary={o.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <AppBar className={classes.appbar} position="fixed">
      <Toolbar>
        <Grid container justify="flex-end" spacing={0}>
          <Grid item xs={6} md={2}>
            <Link
              data="home-section"
              color="inherit"
              href="#"
              underline="none"
              onClick={handleScrollToSection}
            >
              <Typography variant="h6" className={classes.title}>
                <Box display="flex" p={1}>
                  <img src={seniortalentLogo} alt="SeniorTalent" />
                </Box>
              </Typography>
            </Link>
          </Grid>
          <Grid container item xs={6} md={10} justify="flex-end">
            <Hidden mdUp>
              <Grid item xs={'auto'}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  aria-label="menu"
                  onClick={toggleDrawer('top', true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="top"
                  open={state['top']}
                  onClose={toggleDrawer('top', false)}
                >
                  {list('top')}
                </Drawer>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Button
                data="whatwedo-section"
                color="inherit"
                className={classes.item}
                onClick={handleScrollToSection}
              >
                ¿Qué hacemos?
              </Button>

              <Button
                data="customer-section"
                color="inherit"
                className={classes.item}
                onClick={handleScrollToSection}
              >
                ¿Eres un Senior Talent?
              </Button>

              <Button
                data="client-section"
                color="inherit"
                className={classes.item}
                onClick={handleScrollToSection}
              >
                Nuestros Clientes
              </Button>

              <Button
                data="benefit-section"
                color="inherit"
                className={classes.item}
                onClick={handleScrollToSection}
              >
                Nuestro Compromiso
              </Button>

              <Button
                data="contact-section"
                color="inherit"
                className={classes.item}
                onClick={handleScrollToSection}
              >
                Contacto
              </Button>

              <Button
                color="inherit"
                className={classes.item}
                onClick={openAdminPanel}
              >
                Acceso <PersonIcon></PersonIcon>
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
