import React from 'react';
import Structure from '../structure';
import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import whatwedoImage from '../../images/whatwedo.svg';
import { FONT_FAMILY, THEME_COLOR } from '../../helpers/constants';

const WhatWeDo = () => {
  const classes = Structure();

  return (
    <Grid
      container
      spacing={1}
      style={{ backgroundColor: '#FFF', height: '75vh' }}
    >
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        sm={12}
      >
        <Typography
          variant="h6"
          style={{
            fontSize: '48px',
            fontFamily: FONT_FAMILY,
            color: '#3155A4',
          }}
        >
          ¿Qué hacemos?
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Hidden smDown>
          <div style={{ flexGrow: 1, textAlign: 'rigth' }}>
            <img src={whatwedoImage} alt="Contexto" style={{ width: '90%' }} />
          </div>
        </Hidden>
      </Grid>
      <Grid item sm={12} md={6}>
        <div style={{ flexGrow: 1, height: '85vh', minHeight: '85vh' }}>
          <Box p={1} display="flex" flexDirection="column" height="100%">
            <Box mt={1} style={{ width: '29em' }}>
              <Typography
                variant="h6"
                className={classes.subtitle}
                style={{
                  fontSize: '20px',
                  fontFamily: FONT_FAMILY,
                  fontWeight: '700',
                  color: '#3155A4',
                }}
              >
                Queremos aportar soluciones efectivas a la optimización del
                talento de profesionales sobre 50 años, buscamos hacer MATCH
              </Typography>
            </Box>
            <Box mt={1} style={{ width: '29em' }}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: FONT_FAMILY,
                  fontSize: '18px',
                  color: '#445058',
                }}
              >
                Impulsamos el <b>calce perfecto</b> entre las necesidades de
                talento del mundo empresarial, principalmente de PYMES y
                Startups con el activo desaprovechado de profesionales sobre 50
                años que se encuentran en condiciones de entregar todo su
                conocimiento y experiencia, en formatos flexibles y al alcance
                del negocio.
              </Typography>
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default WhatWeDo;
