import React, { useState } from 'react';
import { makeStyles, withStyles, TextField } from '@material-ui/core';

export const useForm = (initialFieldValues, initialErrorsValues, validate) => {
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState(initialErrorsValues);

    const handleInputChange = e => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        });

        setErrors({
          ...errors,
          [name]: !validate[name](value)
        })
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormControl-root": {
          width: '96%',
            margin: theme.spacing(1),
            "background-color": '#fff',
        }
    },
    input: {
      display: 'none',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: '25ch',
    },
}));

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
  })(TextField);

export const Form = (props) => {
    const classes = useStyles();

    return(
        <form className={classes.root}>
            {props.children}
        </form>
    )
}