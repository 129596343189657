import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './components/navbar';
import Customer from './components/customer';
import Benefit from './components/benefits';
import Community from './components/community';
import Contact from './components/contact';
import Footer from './components/footer';
import Intro from './components/intro';
import Alliances from './components/alliances';
import Vision from './components/vision';
import WhatWeDo from './components/whatwedo';
import Client from './components/clients';
import Market from './components/market';
import Protagonist from './components/protagonist';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EAF0FE",
  }
}));

const App = () => {
  const [sections, setSections] = useState({
    "whatwedo-section": React.createRef(),
    "service-section": React.createRef(),
    "benefit-section": React.createRef(),
    "community-section": React.createRef(),
    "contact-section": React.createRef(),
    "customer-section": React.createRef(),
    "client-section": React.createRef(),
    "intro-section": React.createRef(),
  });

  const handleScrollToSection = (e) => {
    const { currentTarget } = e;
    const sectionName = currentTarget.getAttribute('data');

    // // console.log("handleScrollToSection");

    // sections[sectionName].current.scrollIntoView();
    const sectionOffsetTop = sections[sectionName].current.offsetTop;

    window.scrollTo(0, sectionOffsetTop - 65);
  }

  const classes = useStyles();

  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size 
const handleResize = () => {
  if (window.innerWidth < 600) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
}

// create an event listener
useEffect(() => {
  window.addEventListener("resize", handleResize)
})

const styleMobile={
  root:{
    backgroundColor: "#EAF0FE",
    width:'130vw',
  }
}
const style={
  root:{
    backgroundColor: "#EAF0FE",
  }
}

  return (
    <div style={isMobile ? styleMobile.root : style.root}>
      <NavBar handleScrollToSection={handleScrollToSection} />
      <div id="content-principal" style={isMobile ? styleMobile.root : style.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div ref={sections["intro-section"]} id="intro-section" style={{ marginTop: '70px' }}>
              <Intro />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["alliances-section"]} id="alliances-section" style={{ marginTop: '0px' }}>
              <Alliances />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div id="market-section">
              <Market />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["whatwedo-section"]} id="whatwedo-section" style={{ marginTop: '70px' }}>
              <WhatWeDo />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["vision-section"]} id="vision-section" style={{ marginTop: '10px' }}>
              <Vision />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["customer-section"]} id="customer-section">
              <Customer />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["client-section"]} id="client-section">
              <Client />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div ref={sections["benefit-section"]} id="benefit-section">
              <Benefit />
            </div>
          </Grid>


          <Grid item xs={12}>
            <div ref={sections["contact-section"]} id="contact-section"><Contact /></div>
          </Grid>

          <Grid item xs={12}>
            <div id="protagonist-section">
              <Protagonist />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default App;
