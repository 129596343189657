import React, { useState, useEffect } from 'react'
import { IconButton, Tooltip, Grid, Typography, Box } from '@material-ui/core'
import base from '../../config/base'
import firebase from 'firebase/app'
import allowedFileTypes from '../../datasource/mimetypes.json'
import { v4 as uuidv4 } from 'uuid'
import { checkFile as validateFile } from '../../helpers/validate'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CircularProgressWithLabel } from '../progress'
import { FONT_FAMILY } from '../../helpers/constants'

const style = {
  labelForm: {
    fontFamily: FONT_FAMILY,
    color: '#1A202C',
    fontSize: '18px'
  },
  inputForm: {
    fontFamily: FONT_FAMILY,
    color: '#CBD5E0'
  },
  buttonSubmit: {
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  },
  buttonSubmit2: {
    display:'none',
    fontFamily: FONT_FAMILY,
    background: '#FD513B',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '40px'
  },
  textFile: {
    fontFamily: FONT_FAMILY,
  }
}

const Upload = ({ setFileLocation, value, setValues, es6Function }) => {
  const [progress, setProgress] = useState(-1);
  const [showUploadButton, setShowUploadButton] = useState(false);

  const inputRef = React.useRef(null)

  const selectedFileHandler = async (e) => {
    setShowUploadButton(true);
    const file = e.target.files[0]
    await setValues(file)
    inputRef.current.click()
  }

  const getShortFileName = (filename) => {
    return filename
  }

  useEffect(() => {
    setProgress(-1)
    setFileLocation('')
  }, [value])

  /**
   * Handle upload selected files
   */
  const uploadFileHandler = () => {
    console.log("FILE : ", value);
    if (!validateFile(value)) {
      alert(
        'El archivo seleccionado sólo puede ser pdf, doc, docx u odt con un tamaño máximo de 900kb.'
      )
      return false
    }

    const storageRef = base.storage().ref()

    const fileNameStorage = uuidv4()

    const fileRef = storageRef.child('professionals/' + fileNameStorage)

    const taskFileUpload = fileRef.put(value)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion

    taskFileUpload.on(
      'stage_change',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let currentProgress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setProgress(currentProgress)

        // console.log('Upload is ' + progress + '% done');

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log('Upload is paused');
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log('Upload is running');
            break
          default:
            // console.log('Task in unknown state');
            break
        }
      },
      (err) => {
        // TODO: Handle unsuccessful uploads
        setFileLocation('')
      },
      () => {
        // Handle successful uploads on complete
        // Get the download URL
        taskFileUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          setFileLocation(downloadURL)
          es6Function("LLEGUE");
        })
      }
    )
  }

  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={3}>
        <input
          style={{ display: 'none' }}
          id='contained-button-file'
          name='file'
          type='file'
          accept={allowedFileTypes.join(',')}
          onChange={selectedFileHandler}
        />
        <label htmlFor='contained-button-file'>
          <Tooltip title='Seleccionar archivo'>
            <IconButton
              style={style.buttonSubmit}
              aria-label='upload picture'
              component='span'
            >
              Seleccionar Archivo
              <FindInPageIcon />
            </IconButton>
          </Tooltip>
        </label>
      </Grid>
      <Grid item xs={6}>
        <Typography style={style.textFile}>
          {value ? getShortFileName(value.name) : ''}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        {showUploadButton ? (
          <Box>
            <Tooltip title='Subir archivo'>
              <IconButton
               ref={inputRef}
                onClick={uploadFileHandler}
                style={style.buttonSubmit2}
                aria-label='upload picture'
                component='span'
              > Subir Archivo
                 <CloudUploadIcon />
              </IconButton>
            </Tooltip>
            <label>
              {progress == -1 ? (
                ''
              ) : (
                <CircularProgressWithLabel value={progress} />
              )}
            </label>
          </Box>
        ) : (
          <Box />
        )}
      </Grid>
    </Grid>
  )
}

export default Upload
